import { Box, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { shuffle } from 'lodash'; // Importing the shuffle function from a library like Lodash
import React from 'react';
import { hexToRgbaColorString } from '../../Score';
import { DialogPromptQuizWord } from '../../api/DialogPromptApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { getAudioByText } from './DialogPromptComponent';


interface DialogPromptQuizWordComponentProps {
    dialogPrompt: DialogPromptQuizWord;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptQuizWordComponent: React.FC<DialogPromptQuizWordComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const [componentList, setComponentList] = React.useState<any[]>([]);
    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleCorrectDone, setChatBubbleCorrectDone] = React.useState(false);
    const [chatBubbleIncorrectDone, setChatBubbleIncorrectDone] = React.useState(false);

    // const kaiTiFontClass = useKaiTiFontStyles();

    const theme = useTheme();

    const renderWord = (word: string, correct: boolean, index: number) => {

        const circleContainerStyles = {
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: `${64 + word.length * 4}px`,
            height: '64px',
            cursor: 'pointer',
            fontSize: word.length > 1 ? '24px' : '48px',
            fontFamily: 'KaiTi',
        };

        const hoverStyles = {
            backgroundColor: '#d3d3d3',
        };

        return (
            <div
                key={index}
                style={circleContainerStyles}
                onClick={() => handleWordClick(word, correct)}
            >
                {word}
            </div>
        );
    };

    // randomize quiz components
    const components = [
        renderWord(dialogPrompt.new_word, true, 0),
        dialogPrompt.wrong_word_1 && renderWord(dialogPrompt.wrong_word_1, false, 1),
        dialogPrompt.wrong_word_2 && renderWord(dialogPrompt.wrong_word_2, false, 2),
        dialogPrompt.wrong_word_3 && renderWord(dialogPrompt.wrong_word_3, false, 3),
        dialogPrompt.wrong_word_4 && renderWord(dialogPrompt.wrong_word_4, false, 4),
        dialogPrompt.wrong_word_5 && renderWord(dialogPrompt.wrong_word_5, false, 5),
    ];

    let shuffledComponents = shuffle(components.filter(Boolean)); // Shuffle the non-null components

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [yesResponseAudio, setYesResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [noResponseAudio, setNoResponseAudio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.yes_response, setYesResponseAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.no_response, setNoResponseAudio)
    }, []);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [componentList])

    React.useEffect(() => {
        if (chatBubbleDone) {
            shuffledComponents = shuffle(components.filter(Boolean)); // Shuffle the non-null components
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    {shuffledComponents.map((component, index) => (
                        <React.Fragment key={index}>
                            {component}
                        </React.Fragment>
                    ))}
                </Stack>
            )
        }
    }, [chatBubbleDone])

    React.useEffect(() => {
        if (chatBubbleCorrectDone) {
            setDialogPromptComplete(dialogPrompt.id)
        }
    }, [chatBubbleCorrectDone])

    React.useEffect(() => {
        if (chatBubbleIncorrectDone) {
            shuffledComponents = shuffle(components.filter(Boolean)); // Shuffle the non-null components
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                    {shuffledComponents.map((component, index) => (
                        <React.Fragment key={index}>
                            {component}
                        </React.Fragment>
                    ))}
                </Stack>
            )
        }
    }, [chatBubbleIncorrectDone])

    const handleWordClick = (word: string | null, correct: boolean) => {
        const componentsCopy = [...shuffledComponents];
        const promptInputComponentCopy = (
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
                {componentsCopy.map((component, index) => {
                    let componentWord = null;
                    if (component != null && typeof component !== 'string') {
                        componentWord = component.props.children;
                    }
                    return (<>
                        {word == componentWord ?

                            <Box key={index} sx={{
                                borderRadius: '8px',
                                border: (theme) => `1.5px solid ${correct ? theme.palette.success.main : theme.palette.error.main}`,
                                backgroundColor: hexToRgbaColorString(correct ? theme.palette.success.main : theme.palette.error.main, 0.05),
                            }}>
                                {component}
                            </Box>
                            :
                            <Box key={index}>
                                {component}
                            </Box>
                        }
                    </>)
                })}
            </Stack>
        )

        if (correct) {
            if (yesResponseAudio != undefined) {
                const newChatBubble = (
                    <ChatBubble
                        isTeacher={true}
                        message={dialogPrompt.yes_response}
                        messageAudio={yesResponseAudio}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                        setChatBubbleDone={setChatBubbleCorrectDone}
                    />
                );
                setComponentList((prevComponentList) => [...prevComponentList, promptInputComponentCopy, newChatBubble]);
            }
        } else {
            setChatBubbleIncorrectDone(false)
            setPromptInputComponent(null)
            if (word != null) {
                if (noResponseAudio != undefined) {
                    const newChatBubble = (
                        <ChatBubble
                            isTeacher={true}
                            message={dialogPrompt.no_response}
                            messageAudio={noResponseAudio}
                            showAvatar={showAvatar}
                            chatBubbleContainerRef={chatBubbleContainerRef}
                            setChatBubbleDone={setChatBubbleIncorrectDone}
                        />
                    );
                    setComponentList((prevComponentList) => [...prevComponentList, promptInputComponentCopy, newChatBubble]);
                }
            }
        }
    };

    return (
        <Stack>
            {promptAudio != undefined && (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    messageAudio={promptAudio}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )}
            {componentList.map((component, index) => (
                <Box key={index} component="div">
                    {component}
                </Box>
            ))}
        </Stack>
    );
};

export default DialogPromptQuizWordComponent;
