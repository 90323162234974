import { Box, Button, Checkbox, Divider, FormControl, FormControlLabel, Input, InputLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { DialogPlanLlmPrompt } from '../../api/DialogPlanLlmPromptApi';
import { DialogPrompt, DialogPromptImage, DialogPromptIntroduceWord, DialogPromptListenSpeak, DialogPromptListenSpeakNoGrade, DialogPromptQuizQuestion, DialogPromptQuizWord, DialogPromptSpeakNoGrade, DialogPromptTypeSpeak } from '../../api/DialogPromptApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import { dialogPromptClassNameContainsProp } from '../dialogPrompt/DialogPromptForm';
import { arrayBufferToBase64 } from '../dialogPrompt/DialogPromptMatchWordPictureForm';
import DialogPlanLlmPromptQuizQuestionGenerated from './DialogPlanLlmPromptQuizQuestionGenerated';
import DialogPlanLlmPromptQuizWordGenerated from './DialogPlanLlmPromptQuizWordGenerated';
import DialogPlanLlmPromptSpeakNoGradeGenerated from './DialogPlanLlmPromptSpeakNoGradeGenerated';
import DialogPlanLlmPromptTypeSpeakGenerated from './DialogPlanLlmPromptTypeSpeakGenerated';

interface DialogPlanLlmPromptGeneratedProps {
    dialogPrompt?: DialogPrompt; // if llmPrompt was generated
    onSubmit?: (updatedDialogPlanLlmPrompt: DialogPlanLlmPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => void;
    onDelete?: (dialogPlanLlmPromptId: number) => void;

    isRegenerating?: boolean;
    handleLlmPromptChange?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt) => void;
    handleRegenerateDialogPlanLlmPrompt?: (dialogPlanLlmPrompt: DialogPlanLlmPrompt, fieldName: string) => void;
}

const DialogPlanLlmPromptGenerated: React.FC<DialogPlanLlmPromptGeneratedProps> = ({ dialogPrompt, onSubmit, onDelete, isRegenerating, handleLlmPromptChange, handleRegenerateDialogPlanLlmPrompt }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [isTeacher, setIsTeacher] = React.useState(true);
    const [prompt, setPrompt] = React.useState('');
    const [isNoteToStudent, setIsNoteToStudent] = React.useState(Boolean);
    const [orderIndex, setOrderIndex] = React.useState(-1);

    const [imageSource, setImageSource] = React.useState("");
    // we don't' need imageSourceUrl because this is a newly created dialogPrompt

    const [newWord, setNewWord] = React.useState("");
    const [yesResponse, setYesResponse] = React.useState("");
    const [noResponse, setNoResponse] = React.useState("");

    React.useEffect(() => {
        if (dialogPrompt) {
            setDialogPromptFields(dialogPrompt);
        }
    }, [dialogPrompt])

    function setDialogPromptFields(dialogPrompt: DialogPrompt) {
        setIsTeacher(dialogPrompt.is_teacher);
        setPrompt(dialogPrompt.prompt);
        setIsNoteToStudent(dialogPrompt.is_note_to_student ? dialogPrompt.is_note_to_student : false);

        if ("image_src" in dialogPrompt) {
            const dialogPromptImage = dialogPrompt as DialogPromptImage;
            if (dialogPromptImage.image_src != null) {
                setImageSource(dialogPromptImage.image_src);
            }
        }
        if ("new_word" in dialogPrompt) {
            setNewWord((dialogPrompt as DialogPromptIntroduceWord).new_word);
        }
        // if ("yes_button_text" in dialogPlanLlmPrompt) {
        //     setYesButtonText((dialogPlanLlmPrompt as DialogPlanLlmPromptLearnWithCharacter).yes_button_text)
        //     setNoButtonText((dialogPlanLlmPrompt as DialogPlanLlmPromptLearnWithCharacter).no_button_text)
        // }
        if ("no_response" in dialogPrompt) {
            setNoResponse((dialogPrompt as DialogPromptListenSpeak).no_response)
        }
        if ("yes_response" in dialogPrompt) {
            setYesResponse((dialogPrompt as DialogPromptListenSpeakNoGrade).yes_response)
        }
    }

    // update dialog prompt
    const handleIsTeacherChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsTeacher(e.target.value === 'true'); if (dialogPrompt) dialogPrompt.is_teacher = e.target.value === 'true' };
    const handlePromptChange = (e: React.ChangeEvent<HTMLInputElement>) => { setPrompt(e.target.value); if (dialogPrompt) dialogPrompt.prompt = e.target.value };
    const handleImageSourceChange = (e: React.ChangeEvent<HTMLInputElement>) => { setImageSource(e.target.value); if (dialogPrompt) (dialogPrompt as DialogPromptImage).image_src = e.target.value };

    const handleIsNoteToStudentChange = (e: React.ChangeEvent<HTMLInputElement>) => { setIsNoteToStudent(e.target.value === 'on'); if (dialogPrompt) dialogPrompt.is_note_to_student = e.target.value === 'on' };
    const handleNewWordChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNewWord(e.target.value); if (dialogPrompt) (dialogPrompt as DialogPromptIntroduceWord).new_word = e.target.value };

    const handleYesResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setYesResponse(e.target.value); if (dialogPrompt) (dialogPrompt as DialogPromptListenSpeakNoGrade).yes_response = e.target.value };
    const handleNoResponseChange = (e: React.ChangeEvent<HTMLInputElement>) => { setNoResponse(e.target.value); if (dialogPrompt) (dialogPrompt as DialogPromptListenSpeak).no_response = e.target.value };

    const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
        // Clear the image error
        // setImageError(false);

        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            reader.onloadend = () => {
                // Code to handle the reader result
                let base64String: string;
                let result = reader.result;
                if (typeof result === 'string') {
                    base64String = result;
                } else if (result instanceof ArrayBuffer) {
                    base64String = arrayBufferToBase64(result)
                } else {
                    base64String = ""
                }
                setImageSource(base64String);

                if (dialogPrompt) (dialogPrompt as DialogPromptImage).image_src = base64String

            };

            reader.readAsDataURL(file);
        }
    };
    const handleDeleteImage = () => {
        // Set the wordImage state to an empty string to remove the image
        setImageSource('');
        if (dialogPrompt) (dialogPrompt as DialogPromptImage).image_src = ''
    };
    return (<>
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <Divider sx={{ borderColor: '#FFBE19', borderWidth: '2px', mb: 2 }} />  {/* Customized Divider */}

            <FormControl component="fieldset">
                <RadioGroup
                    row
                    aria-label="isTeacher"
                    name="isTeacher"
                    value={isTeacher.toString()}
                    onChange={handleIsTeacherChange}
                >
                    <FormControlLabel value="true" control={<Radio required />} label="Teacher" />
                    <FormControlLabel value="false" control={<Radio required />} label="Student" />
                </RadioGroup>
            </FormControl>
            <div>
                <TextField
                    label="LLM Prompt"
                    value={prompt}
                    onChange={handlePromptChange}
                    multiline
                    rows={5}
                    sx={{ width: '100%', mt: 2 }}
                    inputProps={{
                        style: {
                            resize: 'vertical',
                            overflow: 'hidden',
                        },
                    }}
                />
                {/* Hidden spacer button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        disabled={true}
                        sx={{ opacity: 0 }}
                    >
                        Regenerate
                    </Button>
                </Box>
                <FormControlLabel
                    control={<Checkbox checked={isNoteToStudent} onChange={handleIsNoteToStudentChange} />}
                    label="Is a Note to the Student"
                />
                {!dialogPrompt && <>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;StudentName/&gt; will be replaced with the student's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;TeacherName/&gt; will be replaced with the teacher's name
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Microphone/&gt; will be replaced with the microphone icon for recording
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Speaker/&gt; will be replaced with the speaker icon for listening
                    </Typography>
                    <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                        &lt;Info/&gt; will be replaced with the info icon for more details
                    </Typography>
                </>}
            </div>

            {dialogPrompt && dialogPromptClassNameContainsProp(dialogPrompt.class_name, "image_src") && (<>
                <Box>
                    <InputLabel htmlFor="image-upload">Image</InputLabel>
                    <Input
                        type="file"
                        id="image-upload"
                        inputProps={{ accept: "image/*" }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleImageUpload(e)}
                    />
                </Box>
                {/* Display the image or a placeholder with a fixed height of 300px */}
                <Box
                    sx={{
                        height: '300px',
                        display: 'flex',
                        flexDirection: 'column', // Stack items vertically (image first, button below)
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    {imageSource && (
                        <img
                            src={imageSource}
                            alt="Uploaded"
                            style={{
                                maxHeight: '200px', maxWidth: '200px',
                                height: 'auto', width: 'auto',
                                margin: '0 auto', // Add this line to center the image horizontally
                                display: 'block', // Add this line to ensure the margin works correctly
                            }}
                        />
                    )}
                    {/* Add the delete image button */}
                    {(imageSource && imageSource != "broken") && (
                        <Box sx={{ marginTop: 2 }}>
                            <Button variant="outlined" onClick={handleDeleteImage}>
                                Delete Image
                            </Button>
                        </Box>
                    )}
                </Box>
            </>)}

            {dialogPrompt && dialogPromptClassNameContainsProp(dialogPrompt.class_name, "new_word") && (
                <TextField
                    label="New Word"
                    value={newWord}
                    onChange={handleNewWordChange}
                    required
                />
            )}

            {dialogPrompt && dialogPromptClassNameContainsProp(dialogPrompt.class_name, "yes_response") && (<>
                <TextField
                    label="LLM Prompt for Yes Response"
                    value={yesResponse}
                    onChange={handleYesResponseChange}
                    multiline
                    rows={5}
                    required
                />
                {/* Hidden spacer button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        disabled={true}
                        sx={{ opacity: 0 }}
                    >
                        Regenerate
                    </Button>
                </Box>
            </>)}
            {dialogPrompt && dialogPromptClassNameContainsProp(dialogPrompt.class_name, "no_response") && (<>
                <TextField
                    label="LLM Prompt for No Response"
                    value={noResponse}
                    onChange={handleNoResponseChange}
                    multiline
                    rows={5}
                    required
                />
                {/* Hidden spacer button */}
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                    <Button variant="contained"
                        disabled={true}
                        sx={{ opacity: 0 }}
                    >
                        Regenerate
                    </Button>
                </Box>
            </>)}

            {dialogPrompt && (<>
                {dialogPrompt.class_name == "DialogPromptQuizQuestionModel" &&
                    <DialogPlanLlmPromptQuizQuestionGenerated dialogPrompt={dialogPrompt as DialogPromptQuizQuestion}
                        onSubmit={onSubmit}
                        onDelete={onDelete} isRegenerating={isRegenerating} handleLlmPromptChange={handleLlmPromptChange} handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} />}
                {dialogPrompt.class_name == "DialogPromptQuizWordModel" &&
                    <DialogPlanLlmPromptQuizWordGenerated dialogPrompt={dialogPrompt as DialogPromptQuizWord}
                        onSubmit={onSubmit}
                        onDelete={onDelete} isRegenerating={isRegenerating} handleLlmPromptChange={handleLlmPromptChange} handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} />}
                {dialogPrompt.class_name == "DialogPromptTypeSpeakModel" &&
                    <DialogPlanLlmPromptTypeSpeakGenerated dialogPrompt={dialogPrompt as DialogPromptTypeSpeak}
                        onSubmit={onSubmit}
                        onDelete={onDelete} isRegenerating={isRegenerating} handleLlmPromptChange={handleLlmPromptChange} handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} />}
                {dialogPrompt.class_name == "DialogPromptSpeakNoGradeModel" &&
                    <DialogPlanLlmPromptSpeakNoGradeGenerated dialogPrompt={dialogPrompt as DialogPromptSpeakNoGrade}
                        onSubmit={onSubmit}
                        onDelete={onDelete} isRegenerating={isRegenerating} handleLlmPromptChange={handleLlmPromptChange} handleRegenerateDialogPlanLlmPrompt={handleRegenerateDialogPlanLlmPrompt} />}

            </>)}

        </Stack>
    </>);
};

export default DialogPlanLlmPromptGenerated;