import { Box, Container, Divider, Grid, Stack, Typography } from '@mui/material';

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi, ICourse } from '../api/CourseApi';
import { DialogApi, IDialog } from '../api/DialogApi';
import { ILesson, LessonApi } from '../api/LessonApi';
import CoursePage from '../component/CoursePage';
import DialogPage from '../component/DialogPage';
import LessonPage from '../component/LessonPage';
import SettingsPage from '../component/SettingsPage';
import ErrorMessageContext from '../context/ErrorMessageContext';

import ForwardArrowIcon from '../asset/icon-forward-arrow-grey-on-blue.png';
import LargeBlackIcon from '../asset/icon-menu-large-black.png';
import LargeOrangeIcon from '../asset/icon-menu-large-orange.png';
import LargeWhiteIcon from '../asset/icon-menu-large-white.png';
import MediumOrangeIcon from '../asset/icon-menu-medium-orange.png';
import MediumWhiteIcon from '../asset/icon-menu-medium-white.png';
import SmallOrangeIcon from '../asset/icon-menu-small-orange.png';
import SmallWhiteIcon from '../asset/icon-menu-small-white.png';


export default function CreateLesson() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [courseList, setCourseList] = React.useState<ICourse[]>([]);
    const [selectedCourse, setSelectedCourse] = React.useState<ICourse | null>(null);

    const [lessonList, setLessonList] = React.useState<ILesson[]>([]);
    const [selectedLesson, setSelectedLesson] = React.useState<ILesson | null>(null);

    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);
    const [selectedDialog, setSelectedDialog] = React.useState<IDialog | null>(null);

    const history = useHistory();

    React.useEffect(() => {
        CourseApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getAll() " + response.status)
            })
            .then(list => setCourseList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
        LessonApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getAll() " + response.status)
            })
            .then(list => setLessonList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
        DialogApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogApi.getAll() " + response.status)
            })
            .then(list => setDialogList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }, []);

    /*
        SETTINGS HANDLERS
     */

    function isSettingsComplete() {
        return localStorage.getItem('studentName') != null &&
            localStorage.getItem('studentAvatar') != null &&
            localStorage.getItem('teacher') != null &&
            localStorage.getItem('learnWithCharacter') != null;
    }

    function setSelectedCourseToSettings() {
        const settingsCourse = courseList.find(course => course.name === "Settings");
        //set and unset to force rerender of SettingsPage.
        setSelectedCourse(null)
        setSelectedCourse(settingsCourse!)
        setSelectedLesson(null)
        setSelectedDialog(null)
    }

    /*
        CLEAR
    */

    const clearCourseMenu = () => {
        const updatedCourseList = courseList.map(course => ({
            ...course, // Spread operator to copy properties of the current course object
            expanded: false // Set the expanded property to false
        }));
        setCourseList(updatedCourseList);
    }
    const clearLessonMenu = () => {
        const updatedLessonList = lessonList.map(course => ({
            ...course, // Spread operator to copy properties of the current course object
            expanded: false // Set the expanded property to false
        }));
        setLessonList(updatedLessonList);
    }
    const clearDialogMenu = () => {
        const updatedDialogList = dialogList.map(course => ({
            ...course, // Spread operator to copy properties of the current course object
            expanded: false // Set the expanded property to false
        }));
        setDialogList(updatedDialogList);
    }

    /*
        COURSE HANDLERS
     */

    const expandCourse = (expandCourse: ICourse) => {
        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === expandCourse.id ? { ...course, expanded: true } : { ...course, expanded: false }
            )
        );
    };

    const handleCourseMenuClick = (course: ICourse) => {
        if (isSettingsComplete() == false) {
            setSelectedCourseToSettings()
        } else {
            setSelectedLesson(null)
            setSelectedDialog(null)
            if (course.expanded) {
                setSelectedCourse(null)
                clearCourseMenu();
                clearLessonMenu();
                clearDialogMenu();
            } else {
                setSelectedCourse(course)
                expandCourse(course)
                clearLessonMenu();
                clearDialogMenu();
            }
        }
    };

    /*
        LESSON HANDLERS
     */

    const expandLesson = (expandLesson: ILesson) => {
        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === expandLesson.course_id ? { ...course, expanded: true } : { ...course, expanded: false }
            )
        );
        setLessonList((prevLessonList) =>
            prevLessonList.map((lesson) =>
                lesson.id === expandLesson.id ? { ...lesson, expanded: true } : { ...lesson, expanded: false }
            )
        );
    };

    const handleLessonMenuClick = (lesson: ILesson) => {
        if (isSettingsComplete() == false) {
            setSelectedCourseToSettings()
        } else {
            setSelectedDialog(null)
            if (lesson.expanded) {
                setSelectedLesson(null)
                clearLessonMenu()
                clearDialogMenu()
            } else {
                setSelectedLesson(lesson)
                expandLesson(lesson)
                clearDialogMenu()
            }
        }
    };

    /*
        DIALOG HANDLERS
     */

    const expandDialog = async (expandDialog: IDialog) => {
        const lessonResponse = await LessonApi.getById(expandDialog.lesson_id);
        const lesson = lessonResponse.data;

        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === lesson.course_id ? { ...course, expanded: true } : { ...course, expanded: false }
            )
        );
        setLessonList((prevLessonList) =>
            prevLessonList.map((lesson) =>
                lesson.id === expandDialog.lesson_id ? { ...lesson, expanded: true } : { ...lesson, expanded: false }
            )
        );
        setDialogList((prevDialogList) =>
            prevDialogList.map((dialog) =>
                dialog.id === expandDialog.id ? { ...dialog, expanded: true } : { ...dialog, expanded: false }
            )
        );
    };

    const handleDialogMenuClick = (dialog: IDialog) => {
        if (isSettingsComplete() == false) {
            setSelectedCourseToSettings()
        } else {
            if (dialog.expanded) {
                setSelectedDialog(null)
                clearDialogMenu()
            } else {
                setSelectedDialog(dialog)
                expandDialog(dialog)
            }
        }
    };


    return (
        <Container sx={{
            mx: 0, my: 0, p: 0, pr: 0, backgroundColor: '#002A6E', minWidth: '100vw',
            // Attempt to override with higher specificity
            '&.MuiContainer-root': {
                paddingRight: 0,
            },
        }}>
            <Grid container justifyContent="center" sx={{ p: 0 }}>
                <Grid item sm={12} md={4} alignItems="center" justifyContent="center" sx={{ paddingTop: 2, overflowY: 'auto' }}>
                    <div style={{ height: 'calc(100vh - 98px)' }}>
                        <Stack spacing={2}>
                            {/* Typography aligned to the left */}
                            <Typography variant="h2" color="secondary" sx={{ pl: '32px', pt: 0, pb: 0 }}>
                                Lesson
                            </Typography>
                            {courseList.map(course => course.name == "Settings" ? (
                                <Stack key={course.id} direction="row" alignItems="center" onClick={() => handleCourseMenuClick(course)}>
                                    {course.expanded ? (<>
                                        <img src={LargeOrangeIcon} style={{ maxWidth: '48px', maxHeight: '48px', marginLeft: '9px' }} />
                                        <Typography variant="h4" sx={{ color: '#FCCD56', ml: 1 }}>
                                            {course.name}
                                        </Typography>
                                    </>) : (<>
                                        <img src={LargeWhiteIcon} style={{ maxWidth: '48px', maxHeight: '48px', marginLeft: '9px' }} />
                                        <Typography variant="h4" sx={{ color: '#F2F7FF', ml: 1 }}>
                                            {course.name}
                                        </Typography>
                                    </>)}
                                </Stack >
                            ) : (<div key={course.id}>
                                {course.expanded ? (<>
                                    <Stack direction="row" alignItems="center" onClick={() => handleCourseMenuClick(course)}
                                        sx={{
                                            backgroundColor: '#FCCD56', // Adjust the color as needed
                                            borderRadius: '8px', // Adjust the radius as needed
                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                            mr: 3
                                        }}
                                    >
                                        <img src={LargeBlackIcon} style={{ maxWidth: '48px', maxHeight: '48px' }} />
                                        <Typography variant="h4" sx={{ color: '#251C00', ml: 1 }}>
                                            {course.name}
                                        </Typography>
                                    </Stack>
                                </>) : (<>
                                    <Stack direction="row" alignItems="center" onClick={() => handleCourseMenuClick(course)}
                                        sx={{
                                            border: '1px solid #F2F7FF', // Adjust the color as needed
                                            borderRadius: '8px', // Adjust the radius as needed
                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                            mr: 3
                                        }}
                                    >
                                        <img src={LargeWhiteIcon} style={{ maxWidth: '48px', maxHeight: '48px' }} />
                                        <Typography variant="h4" sx={{ color: '#F2F7FF', ml: 1 }}>
                                            {course.name}
                                        </Typography>
                                    </Stack>
                                </>)}
                                {course.expanded == true ? (
                                    <Stack>
                                        {lessonList
                                            .filter(lesson => lesson.course_id === course.id)
                                            .map(lesson => (<div key={lesson.id}>
                                                {lesson.expanded ? (<>
                                                    <Stack direction="row" alignItems="center" onClick={() => handleLessonMenuClick(lesson)}
                                                        sx={{
                                                            border: '1px solid #FCCD56', // Adjust the color as needed
                                                            borderRadius: '8px', // Adjust the radius as needed
                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                            mr: 3,
                                                            mt: 1
                                                        }}
                                                    >
                                                        <img src={MediumOrangeIcon} style={{ maxWidth: '36px', maxHeight: '36px', marginLeft: '30px' }} />
                                                        <Typography variant="h5" sx={{ color: '#FCCD56', ml: 1 }}>
                                                            {lesson.name}
                                                        </Typography>
                                                    </Stack>
                                                </>) : (<>
                                                    <Stack direction="row" alignItems="center" onClick={() => handleLessonMenuClick(lesson)}
                                                        sx={{
                                                            border: '1px solid #F2F7FF', // Adjust the color as needed
                                                            borderRadius: '8px', // Adjust the radius as needed
                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                            mr: 3,
                                                            mt: 1
                                                        }}
                                                    >
                                                        <img src={MediumWhiteIcon} style={{ maxWidth: '36px', maxHeight: '36px', marginLeft: '30px' }} />
                                                        <Typography variant="h5" sx={{ color: '#F2F7FF', ml: 1 }}>
                                                            {lesson.name}
                                                        </Typography>
                                                    </Stack>
                                                </>)}
                                                {lesson.expanded == true ? (
                                                    <Stack>
                                                        {dialogList
                                                            .filter(dialog => dialog.lesson_id === lesson.id)
                                                            .map(dialog => (<div key={dialog.id}>
                                                                {dialog.expanded ? (<>
                                                                    <Stack direction="row" alignItems="center" onClick={() => handleDialogMenuClick(dialog)}
                                                                        sx={{
                                                                            border: '1px solid #FCCD56', // Adjust the color as needed
                                                                            borderRadius: '8px', // Adjust the radius as needed
                                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                                            mr: 3,
                                                                            mt: 1
                                                                        }}
                                                                    >
                                                                        <img src={SmallOrangeIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '60px' }} />
                                                                        <Typography variant="h6" sx={{ color: '#FCCD56', ml: 1 }}>
                                                                            {dialog.title}
                                                                        </Typography>
                                                                    </Stack>
                                                                </>) : (<>
                                                                    <Stack direction="row" alignItems="center" onClick={() => handleDialogMenuClick(dialog)}
                                                                        sx={{
                                                                            border: '1px solid #F2F7FF', // Adjust the color as needed
                                                                            borderRadius: '8px', // Adjust the radius as needed
                                                                            p: 1, // Add some padding inside the Stack to not have content touching the border
                                                                            mr: 3,
                                                                            mt: 1
                                                                        }}
                                                                    >
                                                                        <img src={SmallWhiteIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '60px' }} />
                                                                        <Typography variant="h6" sx={{ color: '#F2F7FF', ml: 1 }}>
                                                                            {dialog.title}
                                                                        </Typography>
                                                                    </Stack>
                                                                </>)}
                                                                {/* <Stack direction="row" alignItems="center" sx={{ ml: 8 }}>
                                                                    <QuestionAnswerRoundedIcon sx={{ mr: 2, ml: 8 }} />
                                                                    <Typography onClick={() => handleDialogMenuClick(dialog)}>
                                                                        {dialog.title}
                                                                    </Typography>
                                                                </Stack > */}
                                                            </div>))
                                                        }
                                                    </Stack>
                                                ) : null}
                                            </div>))
                                        }
                                    </Stack>
                                ) : null}
                            </div>))}
                        </Stack>
                    </div>
                </Grid>
                <Grid item sm={12} md={8} sx={{ paddingTop: 2, px: 8, paddingBottom: 2, backgroundColor: '#F2F7FF' }}> {/* Adds padding to create a frame */}
                    <Stack spacing={2} sx={{
                        height: '100%', // Ensure Box fills the Grid item vertically
                        width: '100%', // Ensure Box fills the Grid item horizontally
                    }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'start', // Center children horizontally
                            alignItems: 'center', // Center children vertically
                            height: '100%', // Ensure Box fills the Grid item vertically
                            width: '100%', // Ensure Box fills the Grid item horizontally
                            backgroundColor: "#FFFFFF",
                            margin: 'auto', // Center the Box if needed, depending on your layout
                            // padding: 8, // Inner padding within the Box
                            paddingTop: 2,
                            paddingBottom: 1,
                            borderRadius: '16px',
                        }}>
                            <Stack direction="row" sx={{ width: '100%' }}>
                                {selectedCourse != null && (
                                    <Typography variant="h4" color="#52637D" sx={{ marginLeft: '32px' }} >
                                        {selectedCourse ? selectedCourse.name : '\u00A0' /* Non-breaking space if null */}
                                    </Typography>
                                )}
                                {selectedLesson != null && (<>
                                    <img src={ForwardArrowIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '24px' }} />
                                    <Typography variant="h4" color="#52637D" sx={{ marginLeft: '24px' }}>
                                        {selectedLesson ? selectedLesson.name : '\u00A0' /* Non-breaking space if null */}
                                    </Typography>
                                </>)}
                                {selectedDialog != null && (<>
                                    <img src={ForwardArrowIcon} style={{ maxWidth: '24px', maxHeight: '24px', marginLeft: '24px' }} />
                                    <Typography variant="h4" color="#52637D" sx={{ marginLeft: '24px' }}>
                                        {selectedDialog ? selectedDialog.title : '\u00A0' /* Non-breaking space if null */}
                                    </Typography>
                                </>)}
                            </Stack>

                            {/* Add a Divider here */}
                            <Divider sx={{ width: '100%', mt: 1 }} />
                            {selectedDialog != null ? (
                                <DialogPage dialog={selectedDialog} />
                            ) : (<>
                                {selectedLesson != null ? (
                                    <LessonPage lesson={selectedLesson} />
                                ) : (<>
                                    {selectedCourse != null ? (
                                        selectedCourse.name === "Settings" ? (
                                            <SettingsPage course={selectedCourse} />
                                        ) : (
                                            <CoursePage course={selectedCourse} />
                                        )
                                    ) : null}
                                </>)}
                            </>)}
                        </Box>
                    </Stack>
                </Grid>
            </Grid >
        </Container >
    );
}
