import '../style/global.css';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React from "react";
import { useHistory } from 'react-router-dom';
import { ILogin, LoginApi } from '../api/LoginApi';
import { IUser, UserApi } from "../api/UserApi";
import background from '../asset/background-signin.png';
import ErrorMessageContext from "../context/ErrorMessageContext";
import LoginLinkTextContext from "../context/LoginLinkTextContext";

export default function Login() {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const { loginLinkText, setLoginLinkText } = React.useContext(LoginLinkTextContext)

    const [loginErrorMessage, setLoginErrorMessage] = React.useState('');

    const history = useHistory();

    const handleSubmit = (event: { preventDefault: () => void; currentTarget: HTMLFormElement | undefined; }) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const loginObject: ILogin = {
            email: String(data.get("email")),
            password: String(data.get("password")),
            rememberMe: Boolean(data.get("rememberMe"))
        };
        LoginApi.login(loginObject)
            .then(response => {
                if (response.status < 300) {
                    localStorage.setItem('jwt', response.data.token);
                    // Login successful, forward to the homepage
                    history.push('/');
                    UserApi.get()
                        .then(response => {
                            // Handle the response
                            const user: IUser = response.data;
                            if (user.first_name) {
                                localStorage.setItem('studentName', user.first_name);
                            } else {
                                localStorage.removeItem('studentName');
                            }
                            if (user.learn_chinese_character) {
                                localStorage.setItem('learnWithCharacter', user.learn_chinese_character.toString());
                            } else {
                                localStorage.removeItem('learnWithCharacter')
                            }
                            if (user.avatar) {
                                localStorage.setItem('studentAvatar', user.avatar);
                            } else {
                                localStorage.removeItem('studentAvatar')
                            }
                            if (user.chinese_teacher) {
                                localStorage.setItem('teacher', user.chinese_teacher);
                            } else {
                                localStorage.removeItem('teacher')
                            }
                            setLoginLinkText('Logout')
                        })
                        .catch(err => {
                            // Handle the error
                            console.error(err);
                            setErrorMessage('Error getting user:' + err.response.data.message);
                        });
                } else {
                    setLoginErrorMessage("Invalid Login");
                }
            })
            .catch(err => {
                console.error(err)
                setLoginErrorMessage(err.response.data.message);
            });
    };

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundImage: `url(${background})`, // Replace 'path_to_your_image.png' with the actual path to your image
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'auto 100%',
            backgroundPosition: 'right',
            minHeight: 'calc(100vh - 82px)', // Set the container's minimum height to fill the entire viewport, minus menu
            width: '100vw', // Adjusted width to fill the entire viewport horizontally
            padding: 0,
        }}>
            <Container maxWidth="xs">
                <Typography variant="h2">
                    Sign in
                </Typography>
                {/* Error message */}
                {loginErrorMessage && (
                    <Typography color="error" style={{ display: 'flex', justifyContent: 'center' }}>
                        {loginErrorMessage}
                    </Typography>
                )}
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        className="blueInput"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        className="blueInput"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <FormControlLabel
                            control={
                                <Checkbox value="True" color="primary" id="rememberMe" name="rememberMe" />
                            }
                            label="Remember me"
                        />
                    </Box>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/reset_password_email" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="/registration" variant="body2">
                                {"Don't have an account?"}
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Container>

        </div>
    );
}