import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, Stack, TextField } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptConversationListen, DialogPromptConversationListenSpeak, DialogPromptConversationListenWithoutCharacter, DialogPromptConversationReadSpeak, DialogPromptConversationReadWithBlankSpeak, DialogPromptConversationRelated, DialogPromptConversationSpeakOnly, IDialogPromptSentence } from "../../api/DialogPromptApi";
import ErrorMessageContext from "../../context/ErrorMessageContext";

interface DialogPromptConversationRelatedFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
    yesResponse: string;
    noResponse: string;
    dialogPromptClassName: string;
}

export const DialogPromptConversationRelatedForm: React.FC<DialogPromptConversationRelatedFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, dialogPromptClassName }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [dialogPromptConversationListenArray, setDialogPromptConversationListenArray] = React.useState<DialogPromptConversationListen[]>([])
    const [dialogPromptConversationListenId, setDialogPromptConversationListenId] = React.useState("");
    const [dialogPromptRequiredError, setDialogPromptRequiredError] = React.useState(false);

    const [originalDialogPrompt, setOriginalDialogPrompt] = React.useState<DialogPromptConversationRelated | null>(null);
    const [sentenceBlankList, setSentenceBlankList] = React.useState<string[]>([]);
    const [sentenceBlankErrorList, setSentenceBlankErrorList] = React.useState<string[]>([]);

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data as DialogPromptConversationRelated;
                    // set className to field for useEffect below to work
                    // the original className in DB is never correct right???
                    // oldDialogPrompt.class_name = dialogPromptClassName
                    setDialogPromptFields(oldDialogPrompt);
                    // const dialogPromptInQueue = dialogPromptUpdateQueue.some((dialogPrompt) => dialogPrompt.dialogPrompt.id === oldDialogPromptId);
                    // setIsSaving(dialogPromptInQueue)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.response.data.message);
                    }
                })
            DialogPromptApi.getAll(undefined, "DialogPromptConversationListenModel")
                .then(response => {
                    const list = response.data as DialogPromptConversationListen[];
                    const sortedListDescending = list.sort((a, b) => b.id - a.id);
                    setDialogPromptConversationListenArray(sortedListDescending)
                })
                .catch(err => {
                    setDialogPromptConversationListenArray([])
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.response.data.message);
                    }
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptConversationRelated)
        //     // setIsSaving(true)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPromptConversationRelated) {
        setOriginalDialogPrompt(dialogPrompt)
        if (dialogPrompt.dialog_prompt_conversation_listen_id == undefined) {
            setDialogPromptConversationListenId("")
        } else {
            setDialogPromptConversationListenId(String(dialogPrompt.dialog_prompt_conversation_listen_id))
        }
        setDialogPromptRequiredError(false)
        if (dialogPrompt.class_name == "DialogPromptConversationReadWithBlankSpeakModel") {
            const sentenceList = (dialogPrompt as DialogPromptConversationReadWithBlankSpeak).dialog_prompt_sentence_list;
            if (sentenceList) {
                const textList = sentenceList.map(sentence => sentence.sentence_text_alternate == undefined ? "" : sentence.sentence_text_alternate);
                setSentenceBlankList(textList);
            } else {
                setSentenceBlankList([])
            }
        } else {
            setSentenceBlankList([])
        }
        setSentenceBlankErrorList([])
    }

    React.useEffect(() => {
        if (originalDialogPrompt != null &&
            originalDialogPrompt.class_name == "DialogPromptConversationReadWithBlankSpeakModel" &&
            Number(dialogPromptConversationListenId) == originalDialogPrompt.dialog_prompt_conversation_listen_id) {
            const textList = (originalDialogPrompt as DialogPromptConversationReadWithBlankSpeak).dialog_prompt_sentence_list
                .map(sentence => sentence.sentence_text_alternate == undefined ? "" : sentence.sentence_text_alternate);
            setSentenceBlankList(textList);
        } else {
            const selectedPrompt = dialogPromptConversationListenArray.find(prompt => prompt.id == Number(dialogPromptConversationListenId));
            if (selectedPrompt) {
                const textList = selectedPrompt.dialog_prompt_sentence_list.map(sentence => sentence.sentence_text == undefined ? "" : sentence.sentence_text);
                setSentenceBlankList(textList);
            }
        }
    }, [dialogPromptConversationListenId, dialogPromptClassName])

    const handleDialogPromptConversationListenIdChange = (event: SelectChangeEvent) => {
        const selectedValue = event.target.value;
        setDialogPromptConversationListenId(selectedValue);

        // Check if a valid option is selected, you can add more validation if needed
        if (selectedValue === "") {
            // Option is not selected, handle the error here or use a validation library
            setDialogPromptRequiredError(true);
        } else {
            // Option is selected, clear any error state
            setDialogPromptRequiredError(false);
        }
    };

    // Function to handle changes in text inputs
    const handleSentenceBlankInputChange = (index: number, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const updatedValues = [...sentenceBlankList];
        updatedValues[index] = event.target.value;
        setSentenceBlankList(updatedValues);
        setSentenceBlankErrorList(Array.from({ length: updatedValues.length }, () => ''))
    };

    const handleSentenceBlankInputValidation = (index: number) => {
        const selectedDialogPrompt = dialogPromptConversationListenArray.find(
            (dialogPrompt) => dialogPrompt.id === Number(dialogPromptConversationListenId)
        );
        if (selectedDialogPrompt) {
            const originalSentence = selectedDialogPrompt.dialog_prompt_sentence_list[index].sentence_text;
            const blankSentence = sentenceBlankList[index];

            setSentenceBlankErrorList((prevList) => {
                const updatedList = [...prevList]; // Create a copy of the previous list
                updatedList[index] = ""; // Set the i-th element to blank
                return updatedList; // Return the updated list
            });

            if (originalSentence == undefined) {
                setSentenceBlankErrorList((prevList) => {
                    const updatedList = [...prevList]; // Create a copy of the previous list
                    updatedList[index] = 'Missing sentence';
                    return updatedList; // Return the updated list
                });
            }
            else if (originalSentence.length != blankSentence.length) {
                setSentenceBlankErrorList((prevList) => {
                    const updatedList = [...prevList]; // Create a copy of the previous list
                    updatedList[index] = 'Not same length';
                    return updatedList; // Return the updated list
                });
            } else {
                for (let i = 0; i < originalSentence.length; i++) {
                    if (originalSentence[i] !== blankSentence[i] && blankSentence[i] !== "*") {
                        setSentenceBlankErrorList((prevList) => {
                            const updatedList = [...prevList]; // Create a copy of the previous list
                            updatedList[index] = 'Mismatch character / Not star';
                            return updatedList; // Return the updated list
                        });
                    }
                }
            }
        }
    }

    React.useEffect(() => {
        var dialogPrompt = null;
        if (dialogPromptClassName == "DialogPromptConversationListenSpeakModel") {
            dialogPrompt = new DialogPromptConversationListenSpeak(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationListenId),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationListenWithoutCharacterModel") {
            dialogPrompt = new DialogPromptConversationListenWithoutCharacter(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                Number(dialogPromptConversationListenId),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationReadSpeakModel") {
            dialogPrompt = new DialogPromptConversationReadSpeak(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationListenId),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationReadWithBlankSpeakModel") {
            const dialogPromptSentenceList: IDialogPromptSentence[] = sentenceBlankList.map((sentenceBlank: string, index) => ({
                id: -1,
                dialog_prompt_id: -1,
                sentence_id: -1,
                sentence_text_alternate: sentenceBlank,
                order_index: index
            }));
            dialogPrompt = new DialogPromptConversationReadWithBlankSpeak(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationListenId),
                dialogPromptSentenceList
            );
        } else if (dialogPromptClassName == "DialogPromptConversationSpeakOnlyModel") {
            dialogPrompt = new DialogPromptConversationSpeakOnly(
                id,
                dialogId,
                orderIndex,
                isTeacher,
                prompt,
                isNoteToStudent,
                yesResponse,
                noResponse,
                Number(dialogPromptConversationListenId),
            );
        } else if (dialogPromptClassName == "DialogPromptConversationListenModel") {
            // do nothing. just skip error.
        } else {
            throw new Error("Unknown class name " + dialogPromptClassName);
        }
        setNewDialogPrompt(dialogPrompt);
    }, [dialogPromptClassName, id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, yesResponse, noResponse, dialogPromptConversationListenId, sentenceBlankList])

    const selectedDialogPrompt = dialogPromptConversationListenArray.find(
        (dialogPrompt) => dialogPrompt.id === Number(dialogPromptConversationListenId)
    );

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <FormControl fullWidth variant="outlined">
                <InputLabel>Select Conversation</InputLabel>
                <Select
                    required
                    label="Select Conversation"
                    value={dialogPromptConversationListenId}
                    onChange={handleDialogPromptConversationListenIdChange}
                >
                    {dialogPromptConversationListenArray.map((dialogPrompt, index) => (
                        <MenuItem key={index} value={String(dialogPrompt.id)}>
                            {dialogPrompt.dialog_prompt_sentence_list[0].sentence_text}
                        </MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ color: dialogPromptRequiredError ? 'red' : 'inherit' }}>
                    Conversation is required!
                </FormHelperText>
            </FormControl>

            {selectedDialogPrompt && (
                <div>
                    <ul>
                        {selectedDialogPrompt.dialog_prompt_sentence_list.map((sentence, index) => (
                            <li key={index}>
                                {index % 2 === 0 ? 'A: ' : 'B: '}
                                {sentence.sentence_text}
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            {(dialogPromptClassName == "DialogPromptConversationReadWithBlankSpeakModel") && (<>
                <div>
                    Replace any characters with star *
                </div>
                {selectedDialogPrompt && (
                    <div>
                        <ul>
                            {selectedDialogPrompt.dialog_prompt_sentence_list.map((sentence, index) => (
                                <li key={index}>
                                    {index % 2 === 0 ? 'A: ' : 'B: '}
                                    <TextField
                                        variant="outlined"
                                        value={sentenceBlankList[index] || ''}
                                        onChange={(event) => handleSentenceBlankInputChange(index, event)}
                                        onBlur={() => handleSentenceBlankInputValidation(index)}
                                        error={Boolean(sentenceBlankErrorList[index])} // Set error prop based on error presence
                                        helperText={sentenceBlankErrorList[index]} // Display error message
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </>)}
        </Stack>
    )
}