import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { DialogPromptListenSpeak } from '../../api/DialogPromptApi';
import AudioWaveIcon from '../../asset/icons8-audio-wave.gif';
import { useKaiTiFontStyles } from '../../font/KaiTiFont';
import ChatBubble from '../ChatBubble';

import AddSelfStudyBlueIcon from '../../asset/icon-add-self-study-blue.png';
import MicrophoneBlueIcon from '../../asset/icon-microphone-blue.png';
import RightArrowBlueIcon from '../../asset/icon-right-arrow-blue.png';
import SpeakerBlueIcon from '../../asset/icon-speaker-blue.svg';

import { useHistory } from 'react-router-dom';
import { sentenceScoreAllGood, sentenceScoreAnyFailed } from '../../Score';
import { AudioApi } from '../../api/AudioApi';
import { CustomSentenceApi } from '../../api/CustomSentenceApi';
import { ISentenceScore, ScoreApi } from '../../api/ScoreApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import { SentenceScoreMediumResult, SentenceScoreTryAgain, SentenceScoreTryAgainNo, SentenceScoreTryAgainYes, getAudioByText } from './DialogPromptComponent';

interface DialogPromptListenSpeakComponentProps {
    dialogPrompt: DialogPromptListenSpeak;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptListenSpeakComponent: React.FC<DialogPromptListenSpeakComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {

    const [isRecording, setIsRecording] = React.useState(false);
    const [isScoring, setIsScoring] = React.useState(false);

    // count rows needed for sentence
    const stackRef = React.useRef<HTMLDivElement>(null); // Ref for the row stack container
    const [numRows, setNumRows] = React.useState(1);
    const [rowElements, setRowElements] = React.useState<string[][]>([[]]);
    const [elementsPerRow, setElementsPerRow] = React.useState(50);


    const [sentenceScoreList, setSentenceScoreList] = React.useState<ISentenceScore[]>([]);

    const [chatBubbleSentenceScoreDone, setChatBubbleSentenceScoreDone] = React.useState<boolean[]>([]);
    const [chatBubbleTeacherResponseDone, setChatBubbleTeacherResponseDone] = React.useState<boolean[]>([]);
    const [tryAgainClickedList, setTryAgainClickedList] = React.useState<(boolean | null)[]>([]);

    const mediaRecorderRef = React.useRef<MediaRecorder | null>(null);

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);
    const [chatBubbleTryAgainDone, setChatBubbleTryAgainDone] = React.useState(false);
    const [chatBubbleContinueDone, setChatBubbleContinueDone] = React.useState(false);

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const kaiTiFontClass = useKaiTiFontStyles();
    const storedLearnWithCharacter = localStorage.getItem('learnWithCharacter');
    const learnWithCharacter = storedLearnWithCharacter ? JSON.parse(storedLearnWithCharacter) : false;

    let audioData: Blob | null = null;

    const handleSpeakerClick = () => {
        const teacher = localStorage.getItem('teacher');
        let audioPromise;

        if (teacher == "male") {
            audioPromise = AudioApi.getMale(dialogPrompt.sentence_id)
        } else if (teacher == 'female') {
            audioPromise = AudioApi.getFemale(dialogPrompt.sentence_id)
        } else if (teacher == 'female2') {
            audioPromise = AudioApi.getFemale2(dialogPrompt.sentence_id)
        } else {
            throw Error("unknown selected teacher name in local storage " + teacher);
        }


        audioPromise
            .then(response => {
                // check if the response was successful
                if (response.status >= 300) {
                    throw new Error('Getting male audio was not ok statuscode=' + response.status + " " + response.statusText);
                }
                return response.data;
            })
            .then(blob => {
                // create a new Audio object and set the source to the blob URL
                const audio = new Audio(URL.createObjectURL(blob));
                audio.play();
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const startRecording = () => {
        navigator.mediaDevices.getUserMedia({ audio: true })
            .then((stream) => {
                // console.log("startRecording() 1")
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.addEventListener('dataavailable', handleAudioDataAvailable);
                mediaRecorderRef.current.addEventListener('stop', handleAudioDone);

                mediaRecorderRef.current.start();

                setIsRecording(true);
                // console.log("startRecording() 2")
            })
            .catch((err) => {
                console.error(err);
                setErrorMessage('Error accessing microphone:' + err.response.data.message)
            });
    };
    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            // console.log("stopRecording() 1")
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.removeEventListener('dataavailable', handleAudioDataAvailable);
            mediaRecorderRef.current.removeEventListener('stop', handleAudioDone);
            // console.log("stopRecording() 2")
        }

        setIsRecording(false);
    };

    const handleAudioDataAvailable = (event: BlobEvent) => {
        // console.log("handleAudioDataAvailable() 1")
        if (event.data.size > 0) {
            // console.log("event.data.size > 0")
            audioData = event.data;
        }
    };

    const handleAudioDone = () => {
        // console.log("handleAudioDone() 1")
        if (audioData) {
            // console.log("handleAudioDone() has audio data 1")
            setIsScoring(true)
            const formData = new FormData();
            formData.append('audio', audioData);

            ScoreApi.getScore(dialogPrompt.sentence_id, formData)
                .then(response => {
                    setIsScoring(false)
                    // check if the response was successful
                    if (response.status >= 300) {
                        throw new Error('Scoring audio was not ok statuscode=' + response.status + " " + response.statusText);
                    }
                    return response.data;
                })
                .then(responseJson => {
                    responseJson.audioData = audioData;
                    setSentenceScoreList((previous) => [...previous, responseJson]);
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.response.data.message);
                    }
                })
        }
    }

    const [addSentenceDialogOpen, setAddSentenceDialogOpen] = React.useState(false);
    const [addSentenceSuccessMessage, setAddSentenceSuccessMessage] = React.useState('');

    const handleAddSentenceClick = () => {
        setAddSentenceDialogOpen(true);
    };

    const handleConfirm = () => {
        const sentenceId = dialogPrompt.sentence_id
        CustomSentenceApi.createBySentenceId(sentenceId)
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error('Unable to create sentence statuscode=' + response.status + " " + response.statusText);
            })
            .then(list => {
                // Update success message
                setAddSentenceSuccessMessage('Sentence added successfully!');
                // Don't Close the dialog, allow user to see success message
                // setAddSentenceDialogOpen(false);
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else if (err.response.status == 490) {
                    setAddSentenceSuccessMessage("Please validate your email before adding sentences. You can validate your email in your profile.");
                } else if (err.response.status == 491) {
                    setAddSentenceSuccessMessage(err.response['data']);
                } else if (err.response.status == 492) {
                    setAddSentenceSuccessMessage("This user is read only.");
                } else {
                    console.error(err);
                    setAddSentenceSuccessMessage(err.response.data.message);
                }
            })
    };

    const handleClose = () => {
        setAddSentenceDialogOpen(false);
        setAddSentenceSuccessMessage('')
    };
    React.useEffect(() => {
        if (chatBubbleDone && chatBubbleTryAgainDone == false) {
            if (sentenceScoreList.length == 0 || sentenceScoreList[sentenceScoreList.length - 1].passing_grade == false || tryAgainClickedList[sentenceScoreList.length - 1]) {
                setPromptInputComponent(
                    <Stack direction="row" alignItems="center" justifyContent="center">
                        {/* Left-aligned X icon */}
                        <IconButton onClick={handleCancelClick}>
                            <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                        </IconButton>

                        {/* Center-aligned buttons */}
                        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }}>
                            <IconButton onClick={isRecording ? stopRecording : startRecording}>
                                {isRecording ? <img src={AudioWaveIcon} alt="Recording" style={{ width: '32px', height: '32px' }} />
                                    : isScoring ? <CircularProgress size={24} sx={{ width: '24px', height: '24px' }} />
                                        : <img src={MicrophoneBlueIcon} style={{ width: '36px', height: '36px' }} />
                                }
                            </IconButton>
                        </Stack>
                        <Tooltip title="Add to Self-Study" arrow>
                            <IconButton onClick={handleAddSentenceClick} sx={{ alignSelf: 'center' }}>
                                <img src={AddSelfStudyBlueIcon} style={{ width: '36px', height: '36px', marginRight: '8px' }} />
                            </IconButton>
                        </Tooltip>
                    </Stack>

                )
            }
        }
    }, [sentenceScoreList, isRecording, isScoring, chatBubbleDone, chatBubbleTryAgainDone]);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);
    const [yesResponseAudio, setYesResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [noResponseAudio, setNoResponseAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreMediumResultAudio, setSentenceScoreMediumResultAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainAudio, setSentenceScoreTryAgainAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainYesAudio, setSentenceScoreTryAgainYesAudio] = React.useState<Blob | null | undefined>(undefined);
    const [sentenceScoreTryAgainNoAudio, setSentenceScoreTryAgainNoAudio] = React.useState<Blob | null | undefined>(undefined);

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.yes_response, setYesResponseAudio)
        getAudioByText(history, setErrorMessage, dialogPrompt.no_response, setNoResponseAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreMediumResult, setSentenceScoreMediumResultAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgain, setSentenceScoreTryAgainAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgainYes, setSentenceScoreTryAgainYesAudio)
        getAudioByText(history, setErrorMessage, SentenceScoreTryAgainNo, setSentenceScoreTryAgainNoAudio)
    }, []);

    React.useEffect(() => {
        if (chatBubbleContainerRef.current) {
            chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [sentenceScoreList]);

    React.useEffect(() => {
        if (chatBubbleDone && chatBubbleContainerRef.current) {
            const timerId = setTimeout(() => {
                if (chatBubbleContainerRef.current) {
                    chatBubbleContainerRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 50);

            return () => {
                clearTimeout(timerId);
            };
        }
    }, [chatBubbleDone]);

    // Wrap text if it is too long
    React.useEffect(() => {
        if (chatBubbleDone && chatBubbleTryAgainDone == false) {
            const handleResize = () => {
                let newNumRows = 1;
                if (stackRef.current && stackRef.current.parentElement) {
                    if (stackRef.current.children) {
                        if (stackRef.current.children[0].children) {
                            const stackWidth = stackRef.current.parentElement.offsetWidth;

                            let rowWidth = 0;
                            const stackChildren = stackRef.current.children[0].children;
                            for (let i = 0; i < stackChildren.length; i++) {
                                const child = stackChildren[i] as HTMLElement;
                                const childStyles = getComputedStyle(child as HTMLElement);
                                const childWidth = (child as HTMLElement).offsetWidth +
                                    parseFloat(childStyles.paddingLeft) +
                                    parseFloat(childStyles.paddingRight) +
                                    parseFloat(childStyles.marginLeft) +
                                    parseFloat(childStyles.marginRight);
                                rowWidth += childWidth;
                            }
                            newNumRows = Math.ceil(rowWidth / stackWidth);
                        }
                    }
                }
                setNumRows(newNumRows);
            };

            // Call the handleResize function initially and add event listener for resize
            handleResize();
            window.addEventListener('resize', handleResize);

            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [chatBubbleDone, chatBubbleTryAgainDone]);

    React.useEffect(() => {
        if (dialogPrompt.pinyin) {
            const nonNullPinyin = dialogPrompt.pinyin; // Hold the non-null value
            const newElementsPerRow = Math.ceil(nonNullPinyin.length / numRows);
            setElementsPerRow(newElementsPerRow);
            const rows = Array.from({ length: numRows }, (_, i) =>
                nonNullPinyin.slice(i * newElementsPerRow, (i + 1) * newElementsPerRow)
            );
            setRowElements(rows);
        } else {
            // If dialogPrompt.pinyin is null or undefined, set an empty array to clear the rows
            setRowElements([]);
        }
    }, [dialogPrompt.pinyin, numRows]);


    React.useEffect(() => {
        if (chatBubbleTryAgainDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    {/* Left-aligned X icon */}
                    <IconButton onClick={handleCancelClick}>
                        <img src={RightArrowBlueIcon} style={{ width: '36px', height: '36px' }} />
                    </IconButton>

                    {/* Center-aligned buttons */}
                    <Stack direction="row" alignItems="center" justifyContent="center" sx={{ flexGrow: 1 }} spacing={2}>
                        <Button variant="contained" onClick={handleNextWordClick} className="secondaryButton">
                            No, Continue On
                        </Button>
                        <Button variant="contained" onClick={handleTryAgainClick} >
                            Yes, Try Again
                        </Button>
                    </Stack>
                </Stack>

            )
        }
    }, [chatBubbleTryAgainDone]);

    const handleNextWordClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(false)
    }
    const handleTryAgainClick = () => {
        setChatBubbleTryAgainDone(false)
        updateTryAgainClickedList(true)
    }

    const updateTryAgainClickedList = (tryAgain: boolean) => {
        const index = sentenceScoreList.length - 1;
        setTryAgainClickedList(previous => {
            const updatedArray = [...previous];
            updatedArray[index] = tryAgain;
            return updatedArray;
        })
    }

    const [openCancelDialog, setOpenCancelDialog] = React.useState(false);

    const handleCancelClick = () => {
        setOpenCancelDialog(true);
    };

    const handleCancelDialogConfirm = () => {
        setOpenCancelDialog(false);
        setChatBubbleContinueDone(true);
    };

    const handleCancelDialogCancel = () => {
        setOpenCancelDialog(false);
    };

    React.useEffect(() => {
        if (chatBubbleContinueDone) {
            setDialogPromptComplete(dialogPrompt.id)

        }
    }, [chatBubbleContinueDone])

    return (
        <Stack>
            {promptAudio != undefined && (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    messageAudio={promptAudio}
                    showAvatar={showAvatar}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                    setChatBubbleDone={setChatBubbleDone}
                />
            )}

            {chatBubbleDone && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', pb: 2 }}>
                    {rowElements.map((dialogPinyin, rowIndex) => (
                        <Stack direction="row" alignItems="center" justifyContent="center" className="border-dark" style={{ marginBottom: '10px' }} key={rowIndex}
                            sx={{ pb: 1, pl: 2, pr: 3, display: 'inline-flex' }}>
                            {rowIndex == 0 && (
                                <IconButton onClick={handleSpeakerClick}>
                                    <img src={SpeakerBlueIcon} style={{ width: '36px', height: '36px', marginRight: '8px' }} />
                                </IconButton>
                            )}
                            {dialogPinyin.map((pinyinElement, index) => (
                                <Stack key={index} spacing={1} alignItems="center">
                                    <span
                                        style={{ marginLeft: '0.5em', marginRight: '0.5em', fontSize: '32px' }}>
                                        {pinyinElement}
                                    </span>
                                    {learnWithCharacter && (
                                        <div className={kaiTiFontClass.customFont} style={{ fontSize: '48px', margin: 0, padding: 0, lineHeight: 0.9 }} >
                                            <span>{dialogPrompt.new_word[rowIndex * elementsPerRow + index]}</span>
                                        </div>
                                    )}
                                </Stack>
                            ))}
                        </Stack>
                    ))}
                </Box>
            )}

            {sentenceScoreList.map((sentenceScore, index) => (
                <div key={index}>
                    <ChatBubble
                        isTeacher={!dialogPrompt.is_teacher}
                        message={sentenceScore.text}
                        messageAudio={null}
                        sentenceScore={sentenceScore}
                        showAvatar={showAvatar}
                        chatBubbleContainerRef={chatBubbleContainerRef}
                        setChatBubbleDone={(done) => setChatBubbleSentenceScoreDone(previous => {
                            console.log("sentene score updated index " + index)
                            const updatedArray = [...previous];
                            updatedArray[index] = done;
                            return updatedArray;
                        })}
                    />
                    {chatBubbleSentenceScoreDone[index] && (<>
                        {sentenceScoreAllGood(sentenceScore) ? (
                            yesResponseAudio != undefined && (
                                <ChatBubble
                                    isTeacher={dialogPrompt.is_teacher}
                                    message={dialogPrompt.yes_response}
                                    messageAudio={yesResponseAudio}
                                    showAvatar={showAvatar}
                                    chatBubbleContainerRef={chatBubbleContainerRef}
                                    setChatBubbleDone={setChatBubbleContinueDone}
                                />
                            )
                        ) : sentenceScoreAnyFailed(sentenceScore) ? (
                            noResponseAudio != undefined && (
                                <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                    message={dialogPrompt.no_response}
                                    messageAudio={noResponseAudio}
                                    showAvatar={showAvatar}
                                    chatBubbleContainerRef={chatBubbleContainerRef}
                                    setChatBubbleDone={(done) => {
                                        setChatBubbleTryAgainDone(false);
                                        // set null, to skip this index
                                        setTryAgainClickedList((previous) => [...previous, null]);
                                    }}
                                />
                            )
                        ) : (
                            sentenceScoreMediumResultAudio != undefined && (
                                <ChatBubble isTeacher={dialogPrompt.is_teacher}
                                    message={SentenceScoreMediumResult}
                                    messageAudio={sentenceScoreMediumResultAudio}
                                    showAvatar={showAvatar}
                                    chatBubbleContainerRef={chatBubbleContainerRef}
                                    setChatBubbleDone={(done) => setChatBubbleTeacherResponseDone(previous => {
                                        const updatedArray = [...previous];
                                        updatedArray[index] = done;
                                        return updatedArray;
                                    })}
                                />
                            )
                        )}
                        {chatBubbleTeacherResponseDone[index] && sentenceScoreTryAgainAudio != undefined && (
                            <ChatBubble
                                isTeacher={true}
                                message={SentenceScoreTryAgain}
                                messageAudio={sentenceScoreTryAgainAudio}
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleTryAgainDone}
                            />
                        )}
                        {tryAgainClickedList[index] === true && sentenceScoreTryAgainYesAudio != undefined && (
                            <ChatBubble
                                isTeacher={false}
                                message={SentenceScoreTryAgainYes}
                                messageAudio={null} // no audio for student
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                            />
                        )}
                        {tryAgainClickedList[index] === false && sentenceScoreTryAgainNoAudio != undefined && (
                            <ChatBubble
                                isTeacher={false}
                                message={SentenceScoreTryAgainNo}
                                messageAudio={null} // no audio for student
                                showAvatar={showAvatar}
                                chatBubbleContainerRef={chatBubbleContainerRef}
                                setChatBubbleDone={setChatBubbleContinueDone}
                            />

                        )}
                    </>)}
                </div>
            ))}
            {/* Skip Confirmation Dialog */}
            <Dialog open={openCancelDialog} onClose={handleCancelDialogCancel}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirm Skip</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Are you sure you want to skip this exercise?
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCancelDialogCancel} color="primary" sx={{ color: '#787878' }}>
                        Keep trying
                    </Button>
                    <Button onClick={handleCancelDialogConfirm} color="primary" sx={{ color: '#787878' }}>
                        Skip
                    </Button>
                </DialogActions>
            </Dialog>
            {/* Add Sentence Confirmation Dialog */}
            <Dialog open={addSentenceDialogOpen} onClose={handleClose}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Confirmation</Typography></DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ color: '#8D8D8D' }}>
                        Do you want to add this sentence to Self-Study?
                        <p>{dialogPrompt.new_word}</p>
                    </DialogContentText>
                    {/* Display success message inside the Dialog */}
                    {addSentenceSuccessMessage && <p>{addSentenceSuccessMessage}</p>}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleClose} color="primary" sx={{ color: '#787878' }}>
                        {addSentenceSuccessMessage.length == 0 ? "Cancel" : "Close"}
                    </Button>
                    <Button onClick={handleConfirm} color="primary" disabled={addSentenceSuccessMessage.length > 0} sx={{ color: '#787878' }}>
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default DialogPromptListenSpeakComponent;
