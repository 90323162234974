import { Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Select, Stack, TextField } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogApi, IDialog } from "../../api/DialogApi";
import { DialogPrompt, DialogPromptApi, DialogPromptSelectNextDialog } from "../../api/DialogPromptApi";
import { INextDialog } from "../../api/NextDialog";
import ErrorMessageContext from "../../context/ErrorMessageContext";

interface DialogPromptSelectNextDialogFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
}


export const DialogPromptSelectNextDialogForm: React.FC<DialogPromptSelectNextDialogFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [nextDialogList, setNextDialogList] = React.useState<INextDialog[]>([]);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = React.useState(false);
    const [dialogIndexToDelete, setDialogIndexToDelete] = React.useState<number | null>(null);

    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);

    React.useEffect(() => {
        DialogApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogApi.getAll() " + response.status)
            })
            .then(list => setDialogList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }, [])

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data as DialogPromptSelectNextDialog;
                    setDialogPromptFields(oldDialogPrompt);
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.response.data.message);
                    }
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptSelectNextDialog)
        // }
    }, [oldDialogPromptId])


    function setDialogPromptFields(dialogPrompt: DialogPromptSelectNextDialog) {
        if (dialogPrompt.class_name == "DialogPromptSelectNextDialogModel") {
            setNextDialogList(dialogPrompt.next_dialog_list);
        } else {
            setNextDialogList([])
        }
        setDeleteConfirmationOpen(false)
        setDialogIndexToDelete(null)
    }

    React.useEffect(() => {
        const dialogPrompt = new DialogPromptSelectNextDialog(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            nextDialogList
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, nextDialogList])

    const handleAddNextDialog = () => {
        const newNextDialog: INextDialog = {
            id: 0,
            dialog_prompt_id: 0,
            button_text: '',
            response_text: '',
            next_dialog_id: 0,
        };
        if (nextDialogList) {
            setNextDialogList([...nextDialogList, newNextDialog]);
        } else {
            setNextDialogList([newNextDialog]);
        }
    };

    const handleNextDialogChange = (
        index: number,
        field: string,
        value: string | number
    ) => {
        setNextDialogList((nextDialogList) =>
            nextDialogList.map((nextDialog, i) => {
                if (i === index) {
                    return { ...nextDialog, [field]: value };
                }
                return nextDialog;
            })
        );
    };

    const handleDeleteNextDialog = (index: number) => {
        setDialogIndexToDelete(index);
        setDeleteConfirmationOpen(true);
    };

    const handleConfirmDelete = () => {
        if (dialogIndexToDelete !== null) {
            //    vocabularyWordList[wordIndexToDelete].to_be_deleted = true
            // setVocabularyWordList(vocabularyWordList.filter((word) => word.to_be_deleted == false));
            setNextDialogList(nextDialogList => nextDialogList.filter((word, index) => index !== dialogIndexToDelete));
        }
        setDialogIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };

    const handleCancelDelete = () => {
        setDialogIndexToDelete(null);
        setDeleteConfirmationOpen(false);
    };
    return (<>
        {nextDialogList && nextDialogList.map((nextDialog, index) => {
            // Assuming word.image contains the ArrayBuffer
            return (

                <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }} key={index}>
                    <h3>Next Dialog Option</h3>
                    <TextField
                        label="Button text"
                        value={nextDialog.button_text}
                        required
                        onChange={(e) =>
                            handleNextDialogChange(
                                index,
                                'button_text',
                                e.target.value
                            )
                        }
                    />
                    <TextField
                        label="Response Text"
                        value={nextDialog.response_text}
                        required
                        onChange={(e) =>
                            handleNextDialogChange(
                                index,
                                'response_text',
                                e.target.value
                            )
                        }
                    />
                    {/* Dropdown for selecting a dialog */}
                    <Select
                        label="Select Dialog"
                        value={nextDialog.next_dialog_id}
                        required
                        onChange={(e) => handleNextDialogChange(index, 'next_dialog_id', e.target.value as number)}
                    >
                        {/* Default menu item to display when no dialog is selected */}
                        <MenuItem value="" disabled>
                            Select a Dialog
                        </MenuItem>

                        {/* Map through your dialogList and create MenuItem for each dialog */}
                        {dialogList.map((dialog) => (
                            <MenuItem key={dialog.id} value={dialog.id}>
                                {`${dialog.title}`}
                            </MenuItem>
                        ))}
                    </Select>

                    <Button variant="outlined" onClick={() => handleDeleteNextDialog(index)}>
                        Delete
                    </Button>
                </Stack>
            )
        })}
        <Button variant="outlined" onClick={handleAddNextDialog}>
            Add Next Dialog Option
        </Button>
        <Dialog open={deleteConfirmationOpen} onClose={handleCancelDelete}>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this dialog option {dialogIndexToDelete !== null && (<>{nextDialogList[dialogIndexToDelete].button_text} - {nextDialogList[dialogIndexToDelete].response_text}</>)} ?
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancelDelete} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    </>);
};
