import { Stack, TextField, Typography } from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import { DialogPrompt, DialogPromptApi, DialogPromptConversationListen, IDialogPromptSentence } from "../../api/DialogPromptApi";
import ErrorMessageContext from "../../context/ErrorMessageContext";

interface DialogPromptConversationListenFormProps {
    oldDialogPromptId: number;
    setNewDialogPrompt: React.Dispatch<React.SetStateAction<DialogPrompt | null>>;
    id: number;
    dialogId: number;
    orderIndex: number;
    isTeacher: boolean;
    prompt: string;
    isNoteToStudent: boolean;
}

export const DialogPromptConversationListenForm: React.FC<DialogPromptConversationListenFormProps> = ({
    oldDialogPromptId, setNewDialogPrompt,
    id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent }) => {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)
    const history = useHistory();

    const [conversation, setConversation] = React.useState("");
    const handleConversationChange = (e: React.ChangeEvent<HTMLInputElement>) => { setConversation(e.target.value); };

    React.useEffect(() => {
        // is oldDialogPromptId in the update queue (currently being saved)?
        // if so, display the object in memory
        // const oldDialogPromptTuple = dialogPromptUpdateQueue.find((dialogPromptTuple) => dialogPromptTuple.dialogPrompt.id === oldDialogPromptId)
        // if (oldDialogPromptTuple === undefined || oldDialogPromptTuple === null) {
            DialogPromptApi.getById(oldDialogPromptId)
                .then((response) => {
                    const oldDialogPrompt = response.data as DialogPromptConversationListen;
                    setDialogPromptFields(oldDialogPrompt);
                    // const dialogPromptInQueue = dialogPromptUpdateQueue.some((dialogPrompt) => dialogPrompt.dialogPrompt.id === oldDialogPromptId);
                    // setIsSaving(dialogPromptInQueue)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        setErrorMessage(err.response.data.message);
                    }
                })
        // } else {
        //     setDialogPromptFields(oldDialogPromptTuple.dialogPrompt as DialogPromptConversationListen)
        //     // setIsSaving(true)
        // }
    }, [oldDialogPromptId])

    function setDialogPromptFields(dialogPrompt: DialogPromptConversationListen) {
        if (dialogPrompt.class_name == "DialogPromptConversationListenModel") {
            setConversation(dialogPrompt.dialog_prompt_sentence_list
                ? dialogPrompt.dialog_prompt_sentence_list
                    .map(item => item.sentence_text)
                    .join("\n") // Convert array to newline-separated string
                : ""
            )
        } else {
            setConversation("")
        }
    }

    React.useEffect(() => {
        // Convert newline-separated string to array
        const dialogPromptSentenceList: IDialogPromptSentence[] = conversation.split("\n").map((response: string, index) => ({
            id: -1,
            dialog_prompt_id: -1,
            sentence_id: -1,
            sentence_text: response,
            order_index: index
        }));
        const dialogPrompt = new DialogPromptConversationListen(
            id,
            dialogId,
            orderIndex,
            isTeacher,
            prompt,
            isNoteToStudent,
            dialogPromptSentenceList,
        );
        setNewDialogPrompt(dialogPrompt);
    }, [id, dialogId, orderIndex, isTeacher, prompt, isNoteToStudent, conversation])

    return (
        <Stack spacing={4} sx={{ width: '90%', margin: '0 auto', pt: 2, pb: 4 }}>
            <div>
                <TextField
                    label="Conversation"
                    value={conversation}
                    onChange={handleConversationChange}
                    multiline
                    rows={6}
                    sx={{ width: '100%' }}
                    required
                />
                <Typography sx={{ color: 'grey.500', fontSize: '0.9rem' }}>
                    Alternate lines between speaker A and B
                </Typography>

            </div>
        </Stack>
    )
}