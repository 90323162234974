import axios from 'axios';
import { REST_HOSTNAME } from '../Config';

const axiosInstance = axios.create({
    baseURL: REST_HOSTNAME
});

// Add request interceptor
axiosInstance.interceptors.request.use(
    function (config) {
        const jwt = localStorage.getItem('jwt');
        if (jwt) {
            config.headers['x-access-token'] = jwt; // Add JWT to the headers
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

export interface IChat {
    id: number;
    hsk_level: number;
    first_line: string;
}

export interface IChatLine {
    id: number;
    chat_id: number;
    role: string;
    line: string;
    pinyin: string[];
}

export interface IHskDetail {
    hsk_level: number;
    word: string;
    pinyin: string;
    definition: string;
}

export const ChatApi = {
    getAllConversationChat: function () {
        return axiosInstance.request<IChat[]>({
            method: "GET",
            url: '/conversation_chat'
        });
    },
    getConversationChatById: function (chatId: number) {
        return axiosInstance.request<IChat>({
            method: "GET",
            url: '/conversation_chat/' + chatId
        });
    },
    getAllOfficeHourChat: function () {
        return axiosInstance.request<IChat[]>({
            method: "GET",
            url: '/office_hour_chat'
        });
    },
    getOfficeHourChatById: function (chatId: number) {
        return axiosInstance.request<IChat>({
            method: "GET",
            url: '/office_hour_chat/' + chatId
        });
    },
    getChatLineByChat: function (chatId: number) {
        return axiosInstance.request<IChatLine[]>({
            method: "GET",
            url: '/chat_line?chat_id=' + chatId
        });
    },
    getChatLine: function (chatLineId: number) {
        return axiosInstance.request<IChatLine>({
            method: "GET",
            url: '/chat_line/' + chatLineId
        });
    },
    startNewConversationChatLine: function (chatLine: IChatLine, hskLevel: number) {
        return axiosInstance.request<IChatLine[]>({
            method: "POST",
            url: '/conversation_chat/chat_line/' + hskLevel,
            data: chatLine
        });
    },
    startNewOfficeHourChatLine: function (chatLine: IChatLine, hskLevel: number) {
        return axiosInstance.request<IChatLine[]>({
            method: "POST",
            url: '/office_hour_chat/chat_line/' + hskLevel,
            data: chatLine
        });
    },
    addConversationChatLine: function (chatLine: IChatLine, hskLevel: number) {
        return axiosInstance.request<IChatLine[]>({
            method: "PUT",
            url: '/conversation_chat/chat_line?hsk_level=' + hskLevel,
            data: chatLine
        });
    },
    addOfficeHourChatLine: function (chatLine: IChatLine, hskLevel: number) {
        return axiosInstance.request<IChatLine[]>({
            method: "PUT",
            url: '/office_hour_chat/chat_line?hsk_level=' + hskLevel,
            data: chatLine
        });
    },
    getHskDetailDetail: function(chatLineId: number) {
        return axiosInstance.request<IHskDetail[]>({
            method: "GET",
            url: '/hsk_detail/' + chatLineId
        });

    }
}
