import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Input, Stack, Typography } from '@mui/material';
import React from 'react';
import { DialogPromptSelectAvatar } from '../../api/DialogPromptApi';
import { IUser, UserApi } from '../../api/UserApi';
import ErrorMessageContext from '../../context/ErrorMessageContext';
import ChatBubble from '../ChatBubble';
import { getAudioByText } from './DialogPromptComponent';

interface DialogPromptSelectAvatarComponentProps {
    dialogPrompt: DialogPromptSelectAvatar;
    setDialogPromptComplete: (dialogPromptId: number) => void;
    setPromptInputComponent: (component: React.ReactNode | null) => void;
    showAvatar?: boolean;
    chatBubbleContainerRef: React.RefObject<HTMLDivElement>;
}

const DialogPromptSelectAvatarComponent: React.FC<DialogPromptSelectAvatarComponentProps> = ({ dialogPrompt, setDialogPromptComplete, setPromptInputComponent, showAvatar = true, chatBubbleContainerRef }) => {
    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [fileSizeError, setFileSizeError] = React.useState(false);

    const [studentAvatar, setStudentAvatar] = React.useState<string | null>(null);

    const [chatBubbleDone, setChatBubbleDone] = React.useState(false);

    const [promptAudio, setPromptAudio] = React.useState<Blob | null | undefined>(undefined);

    const handleFileUpload = () => {
        if (fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files.length > 0) {
            const file = fileInputRef.current.files[0];
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target) {
                    const fileData = event.target.result; // This can be a Blob or data URL

                    if (fileData) {
                        const fileSizeInBytes = file.size;
                        const maxSizeInBytes = 3 * 1024 * 1024; // Maximum file size in bytes (e.g., 128k)

                        if (fileSizeInBytes > maxSizeInBytes) {
                            setFileSizeError(true); // Set the error state to display the dialog
                        } else {
                            // Save the file data in local storage
                            const avatarFileData = fileData.toString()
                            localStorage.setItem('studentAvatar', avatarFileData);
                            setStudentAvatar(avatarFileData);
                            setDialogPromptComplete(dialogPrompt.id);
                            const updatedUser: IUser = {
                                avatar: avatarFileData,
                            };
                            // Call the update function
                            UserApi.update(updatedUser)
                                .then(response => {
                                    // Handle the response
                                    // console.log('User updated:', response.data);
                                })
                                .catch(err => {
                                    console.error(err);
                                    // Handle the error
                                    setErrorMessage('Error updating user:' + err.response.data.message);
                                });
                        }
                    }
                }
            };

            reader.readAsDataURL(file); // Read the file data            
        }
    };

    const handleCloseDialog = () => {
        setFileSizeError(false);
    };

    React.useEffect(() => {
        // clear promptInputComponent initially
        setPromptInputComponent(null);
        getAudioByText(history, setErrorMessage, dialogPrompt.prompt, setPromptAudio)
    }, []);

    React.useEffect(() => {
        if (chatBubbleDone) {
            setPromptInputComponent(
                <Stack direction="row" alignItems="center" justifyContent="center">
                    <Input
                        type="file"
                        inputRef={fileInputRef}
                        onChange={handleFileUpload}
                        style={{ display: 'none' }}
                        id="file-upload"
                        inputProps={{ accept: 'image/*' }}
                    />
                    <label htmlFor="file-upload">
                        <Button variant="contained" component="span">
                            Upload Avatar
                        </Button>
                    </label>
                </Stack>
            )
        }
    }, [chatBubbleDone]);

    return (
        <Stack>
            {promptAudio != undefined && (
                <ChatBubble
                    isTeacher={dialogPrompt.is_teacher}
                    message={dialogPrompt.prompt}
                    messageAudio={promptAudio}
                    showAvatar={showAvatar}
                    setChatBubbleDone={setChatBubbleDone}
                    chatBubbleContainerRef={chatBubbleContainerRef}
                />
            )}
            {studentAvatar == null ? null : (
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img
                        src={studentAvatar || ''}
                        style={{ maxHeight: '4em', maxWidth: '4em' }}
                    />
                </div>
            )}
            <Dialog open={fileSizeError} onClose={handleCloseDialog}>
                <DialogTitle><Typography variant="h4" sx={{ color: '#383838' }}>Error</Typography></DialogTitle>
                <DialogContent>
                    <Typography sx={{ color: '#8D8D8D' }}>The file size exceeds the maximum allowed size of 3MB.</Typography>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'space-between', '& .MuiButton-root': { flex: 1 } }}>
                    <Button onClick={handleCloseDialog} sx={{ color: '#787878' }}>OK</Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default DialogPromptSelectAvatarComponent;
