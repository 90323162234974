import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import { Badge, Button, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material';

import ArticleRoundedIcon from '@mui/icons-material/ArticleRounded';
import MenuBookRoundedIcon from '@mui/icons-material/MenuBookRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import SmsRoundedIcon from '@mui/icons-material/SmsRounded';

import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { CourseApi, ICourse } from '../api/CourseApi';
import { DialogApi, IDialog } from '../api/DialogApi';
import { DialogPrompt, DialogPromptApi, IDialogPromptSentence } from '../api/DialogPromptApi';
import { ILesson, LessonApi } from '../api/LessonApi';
import { SentenceApi } from '../api/SentenceApi';
import { IVocabularyWord } from '../api/VocabularyWordApi';
import CourseForm from '../component/CourseForm';
import DialogForm from '../component/DialogForm';
import LessonForm from '../component/LessonForm';
import DialogPromptForm from '../component/dialogPrompt/DialogPromptForm';
import ErrorMessageContext from '../context/ErrorMessageContext';

// export type DialogPromptTuple = {
//     id: number;
//     dialogPrompt: DialogPrompt;
// };

export default function CreateLesson() {

    const { errorMessage, setErrorMessage } = React.useContext(ErrorMessageContext)

    const [courseList, setCourseList] = React.useState<ICourse[]>([]);
    const [selectedCourse, setSelectedCourse] = React.useState<ICourse | null>(null);

    const [lessonList, setLessonList] = React.useState<ILesson[]>([]);
    const [selectedLesson, setSelectedLesson] = React.useState<ILesson | null>(null);

    const [dialogList, setDialogList] = React.useState<IDialog[]>([]);
    const [selectedDialog, setSelectedDialog] = React.useState<IDialog | null>(null);

    const [dialogPromptList, setDialogPromptList] = React.useState<DialogPrompt[]>([]);
    const [selectedDialogPrompt, setSelectedDialogPrompt] = React.useState<DialogPrompt | null>(null);

    // const [dialogPromptUpdateIdSequence, setDialogPromptUpdateIdSequence] = React.useState(0);
    // const [dialogPromptUpdateQueue, setDialogPromptUpdateQueue] = React.useState<DialogPromptTuple[]>([])
    // const [idSequenceSubmitted, setIdSequenceSubmitted] = React.useState<number[]>([]);

    const history = useHistory();

    React.useEffect(() => {
        CourseApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to CourseApi.getAll() " + response.status)
            })
            .then(list => setCourseList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
        LessonApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to LessonApi.getAll() " + response.status)
            })
            .then(list => setLessonList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
        DialogApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogApi.getAll() " + response.status)
            })
            .then(list => setDialogList(list))
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
        DialogPromptApi.getAll()
            .then(response => {
                if (response.status < 300) {
                    return response.data;
                }
                throw new Error("Unable to DialogPromptApi.getAll() " + response.status)
            })
            .then(list => {
                setDialogPromptList(list)
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }, []);

    /*
        COURSE HANDLERS
     */

    const handleExpandCourse = (courseId: number) => {
        setCourseList((prevCourseList) =>
            prevCourseList.map((course) =>
                course.id === courseId ? { ...course, expanded: !course.expanded } : course
            )
        );
    };

    const handleAddCourse = async () => {
        const largestOrderIndex = courseList.reduce((maxOrderIndex, course) => {
            return Math.max(maxOrderIndex, course.order_index);
        }, 0);
        const newCourse: ICourse = {
            id: 0,
            order_index: Math.ceil(largestOrderIndex + 1),
            name: 'New Course Name',
            description: 'New Course Description'
        };
        CourseApi.create(newCourse)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(newCourseWithId => {
                setSelectedCourse(newCourseWithId)
                setSelectedLesson(null)
                setSelectedDialog(null)
                setSelectedDialogPrompt(null)
                CourseApi.getAll().then(response => {
                    setCourseList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleCourseMenuClick = (course: ICourse) => {
        setSelectedCourse(course)
        setSelectedLesson(null)
        setSelectedDialog(null)
        setSelectedDialogPrompt(null)
        handleExpandCourse(course.id)
    };

    const handleCourseFormSubmit = async (updatedCourse: ICourse) => {
        CourseApi.update(updatedCourse)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(response => {
                CourseApi.getAll().then(response => {
                    setCourseList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleDeleteCourse = async (courseId: number) => {
        CourseApi.delete(courseId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(response => {
                setSelectedCourse(null)
                CourseApi.getAll().then(response => {
                    setCourseList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }

    /*
        LESSON HANDLERS
     */

    const handleExpandLesson = (lessonId: number) => {
        setLessonList((prevLessonList) =>
            prevLessonList.map((lesson) =>
                lesson.id === lessonId ? { ...lesson, expanded: !lesson.expanded } : lesson
            )
        );
    };

    const handleAddLesson = async (courseId: number) => {
        const largestOrderIndex = lessonList.reduce((maxOrderIndex, lesson) => {
            return Math.max(maxOrderIndex, lesson.order_index);
        }, 0);
        const newLesson: ILesson = {
            id: 0,
            course_id: courseId,
            order_index: Math.ceil(largestOrderIndex + 1),
            name: 'New Lesson Name',
            description: null
        };
        LessonApi.create(newLesson)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(newLessonWithId => {
                setSelectedCourse(null)
                setSelectedLesson(newLessonWithId)
                setSelectedDialog(null)
                setSelectedDialogPrompt(null)
                LessonApi.getAll().then(response => {
                    setLessonList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleLessonMenuClick = (lesson: ILesson) => {
        setSelectedCourse(null)
        setSelectedLesson(lesson)
        setSelectedDialog(null)
        setSelectedDialogPrompt(null)
        handleExpandLesson(lesson.id)
    };


    const handleLessonFormSubmit = async (updatedLesson: ILesson) => {
        LessonApi.update(updatedLesson)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(response => {
                LessonApi.getAll().then(response => {
                    setLessonList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleDeleteLesson = async (lessonId: number) => {
        LessonApi.delete(lessonId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(response => {
                setSelectedLesson(null)
                LessonApi.getAll().then(response => {
                    setLessonList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }

    /*
        DIALOG HANDLERS
     */

    const handleExpandDialog = (dialogId: number) => {
        setDialogList((prevDialogList) =>
            prevDialogList.map((dialog) =>
                dialog.id === dialogId ? { ...dialog, expanded: !dialog.expanded } : dialog
            )
        );
        // Filter dialogPromptList for prompts that belong to the dialogId
        const relevantDialogPrompts = dialogPromptList.filter(dialogPrompt => dialogPrompt.dialog_id === dialogId);

        // Initiate processing queue checks without waiting for them.
        relevantDialogPrompts.forEach(dialogPrompt => {
            getSentenceProcessingQueue(dialogPrompt).then(processingQueue => {
                // Handle the result. For example, update a state specific to this dialogPrompt.
                // This could be setting a flag to show a badge, enabling/disabling UI elements, etc.
                // Example (pseudocode):
                setProcessingQueueStatus(prev => ({ ...prev, [dialogPrompt.id]: processingQueue }));
            }).catch(err => {
                console.error('Error getting sentence processing queue', err);
                // Handle any errors, maybe set some error state here.
            });
        });
    };

    const handleAddDialog = async (lessonId: number) => {
        const largestOrderIndex = dialogList.reduce((maxOrderIndex, dialog) => {
            return Math.max(maxOrderIndex, dialog.order_index);
        }, 0);
        const newDialog: IDialog = {
            id: 0,
            lesson_id: lessonId,
            order_index: Math.ceil(largestOrderIndex + 1),
            title: 'New Dialog Title'
        };
        DialogApi.create(newDialog)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(newDialogWithId => {
                setSelectedCourse(null)
                setSelectedLesson(null)
                setSelectedDialog(newDialogWithId)
                setSelectedDialogPrompt(null)
                DialogApi.getAll().then(response => {
                    setDialogList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleDialogMenuClick = (dialog: IDialog) => {
        setSelectedCourse(null)
        setSelectedLesson(null)
        setSelectedDialog(dialog)
        setSelectedDialogPrompt(null)
        handleExpandDialog(dialog.id)
    };

    const handleDialogFormSubmit = async (updatedDialog: IDialog) => {
        DialogApi.update(updatedDialog)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(response => {
                DialogApi.getAll().then(response => {
                    setDialogList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleDeleteDialog = async (dialogId: number) => {
        DialogApi.delete(dialogId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(response => {
                setSelectedDialog(null)
                DialogApi.getAll().then(response => {
                    setDialogList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }

    /*
        DIALOG PROMPT HANDLERS
     */

    const handleAddDialogPrompt = async (dialogId: number) => {
        const largestOrderIndex = dialogPromptList.reduce((maxOrderIndex, dialogPrompt) => {
            return Math.max(maxOrderIndex, dialogPrompt.order_index);
        }, 0);
        const newDialogPrompt: DialogPrompt = {
            id: 0,
            dialog_id: dialogId,
            order_index: Math.ceil(largestOrderIndex + 1),
            is_teacher: true,
            prompt: 'New prompt',
            is_note_to_student: false,
            class_name: "DialogPromptModel",
            has_user_input: false
        };
        DialogPromptApi.create(newDialogPrompt)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return null
            })
            .then(newDialogPromptWithId => {
                setSelectedCourse(null)
                setSelectedLesson(null)
                setSelectedDialog(null)
                setSelectedDialogPrompt(newDialogPromptWithId)
                DialogPromptApi.getAll().then(response => {
                    setDialogPromptList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleDialogPromptMenuClick = (dialogPrompt: DialogPrompt) => {
        setSelectedCourse(null)
        setSelectedLesson(null)
        setSelectedDialog(null)
        setSelectedDialogPrompt(dialogPrompt)
    };

    const handleDialogPromptFormSubmit = async (updatedDialogPrompt: DialogPrompt, setIsSaving: React.Dispatch<React.SetStateAction<boolean>>) => {
        // const updateId = dialogPromptUpdateIdSequence
        // setDialogPromptUpdateIdSequence((updateId) => updateId + 1);
        // setDialogPromptUpdateQueue((prevQueue) => [...prevQueue, { id: updateId, dialogPrompt: updatedDialogPrompt }])
        // setIsSaving(false)

        DialogPromptApi.update(updatedDialogPrompt)
            .then(responseList => {
                const response = responseList[0]
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
            })
            .then(response => {
                DialogPromptApi.getAll().then(response => {
                    const list = response.data
                    setDialogPromptList(list)
                    const newlyUpdatedDialogPrompt = list.find((dialogPrompt: DialogPrompt) => dialogPrompt.id === updatedDialogPrompt.id);
                    if (newlyUpdatedDialogPrompt) {
                        getSentenceProcessingQueue(newlyUpdatedDialogPrompt).then(processingQueue => {
                            setProcessingQueueStatus(prev => ({ ...prev, [newlyUpdatedDialogPrompt.id]: processingQueue }));
                        });
                    }
                    setIsSaving(false)
                })
                .finally(() => {
                    setIsSaving(false)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    };

    const handleDeleteDialogPrompt = async (dialogPromptId: number) => {
        DialogPromptApi.delete(dialogPromptId)
            .then(response => {
                if (response.status < 300) {
                    return response.data
                }
                setErrorMessage(response.statusText);
                return []
            })
            .then(response => {
                setSelectedDialogPrompt(null)
                DialogPromptApi.getAll().then(response => {
                    setDialogPromptList(response.data)
                })
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    history.push('/login');
                } else {
                    console.error(err);
                    setErrorMessage(err.response.data.message);
                }
            })
    }

    interface HasSentenceId {
        sentence_id: number;
        teacher_chinese_prompt_sentence_id: number;
    }
    interface HasDialogPromptSentenceList {
        dialog_prompt_sentence_list: IDialogPromptSentence[];
    }
    interface HasVocabularyWord {
        correct_vocabulary_word: IVocabularyWord;
        incorrect_vocabulary_word_list: IVocabularyWord[];
    }

    const [processingQueueStatus, setProcessingQueueStatus] = React.useState<{ [key: number]: number }>({});

    React.useEffect(() => {
        dialogPromptList.forEach(dialogPrompt => {
            if (selectedDialog && dialogPrompt.dialog_id === selectedDialog.id) {
                getSentenceProcessingQueue(dialogPrompt).then(processingQueue => {
                    setProcessingQueueStatus(prev => ({ ...prev, [dialogPrompt.id]: processingQueue }));
                });
            }
        });
    }, [dialogPromptList, selectedDialog, selectedLesson]); // Add any other dependencies if necessary

    const getSentenceProcessingQueue = (dialogPrompt: DialogPrompt) => {
        console.log("getSentenceProcessingQueue " + dialogPrompt.id)
        const sentenceIdList: number[] = []

        if ("sentence_id" in dialogPrompt) {
            const dialogWithSentenceId = dialogPrompt as DialogPrompt & HasSentenceId;
            if (dialogWithSentenceId.sentence_id) sentenceIdList.push(dialogWithSentenceId.sentence_id)
        }
        if ("teacher_chinese_prompt_sentence_id" in dialogPrompt) {
            const dialogWithSentenceId = dialogPrompt as DialogPrompt & HasSentenceId;
            if (dialogWithSentenceId.teacher_chinese_prompt_sentence_id) sentenceIdList.push(dialogWithSentenceId.teacher_chinese_prompt_sentence_id)
        }
        if ("correct_vocabulary_word" in dialogPrompt) {
            const dialogWithVocabularyWord = dialogPrompt as DialogPrompt & HasVocabularyWord;
            const correct_vocabulary_word = dialogWithVocabularyWord.correct_vocabulary_word
            if (correct_vocabulary_word) {
                if (correct_vocabulary_word.audio_sentence_id) sentenceIdList.push(correct_vocabulary_word.audio_sentence_id)
                if (correct_vocabulary_word.teacher_chinese_prompt_sentence_id) sentenceIdList.push(correct_vocabulary_word.teacher_chinese_prompt_sentence_id)
                if (correct_vocabulary_word.student_response_prompt_sentence_id) sentenceIdList.push(correct_vocabulary_word.student_response_prompt_sentence_id)
            }
        }
        if ("incorrect_vocabulary_word_list" in dialogPrompt) {
            const dialogWithVocabularyWord = dialogPrompt as DialogPrompt & HasVocabularyWord;
            dialogWithVocabularyWord.incorrect_vocabulary_word_list.map(vocabularyWord => {
                if (vocabularyWord.audio_sentence_id) sentenceIdList.push(vocabularyWord.audio_sentence_id)
                if (vocabularyWord.teacher_chinese_prompt_sentence_id) sentenceIdList.push(vocabularyWord.teacher_chinese_prompt_sentence_id)
                if (vocabularyWord.student_response_prompt_sentence_id) sentenceIdList.push(vocabularyWord.student_response_prompt_sentence_id)
            })
        }
        if ("dialog_prompt_sentence_list" in dialogPrompt) {
            const dialogWithSentenceId = dialogPrompt as DialogPrompt & HasDialogPromptSentenceList;
            dialogWithSentenceId.dialog_prompt_sentence_list.map(dialogPromptSentence => {
                // Return the promise from the API call for each sentence_id
                if (dialogPromptSentence.sentence_id) sentenceIdList.push(dialogPromptSentence.sentence_id);
            });
        }
        // Map over sentenceIds to create an array of promises
        const sentenceApiPromises = sentenceIdList.map(sentenceId => SentenceApi.getById(sentenceId));

        // Use Promise.all to wait for all promises to resolve
        return Promise.all(sentenceApiPromises).then(responses => {
            // Extract processing_queue from each response and find the max value
            const maxProcessingQueue = responses.reduce((max, response) => {
                const currentQueue = response.data.processing_queue;
                return currentQueue == null ? -1 : (currentQueue > max ? currentQueue : max);
            }, 0); // Initialize max with 0 or another suitable minimum value

            console.log("DialogPromptId", dialogPrompt.id, "Maximum processing_queue:", maxProcessingQueue);
            return maxProcessingQueue
        });
    }

    /*
    // dialog prompt update queue functions
    React.useEffect(() => {
        // Start processing the queue
        processNextDialogPrompt();
    }, [dialogPromptUpdateQueue]);

    const processNextDialogPrompt = () => {
        if (dialogPromptUpdateQueue.length <= 0) {
            // Continue processing the next dialogPrompt after a delay (adjust the delay value as needed)
            // setTimeout(processNextDialogPrompt, 1000); // Wait 1 second before processing the next item
            // console.log("processNextDialogPrompt() empty")
        } else if (idSequenceSubmitted.includes(dialogPromptUpdateQueue[0].id)) {
            // already processed
            // most likely currently running
        } else {
            // console.log("processNextDialogPrompt() processing")
            const dialogPromptToUpdate = dialogPromptUpdateQueue[0];
            setIdSequenceSubmitted((prevIds) => [...prevIds, dialogPromptToUpdate.id]);
            DialogPromptApi.update(dialogPromptToUpdate.dialogPrompt)
                .then(responseList => {
                    const response = responseList[0]
                    if (response.status < 300) {
                        return response.data
                    }
                    setErrorMessage(response.statusText);
                })
                .then(list => {
                    setDialogPromptList(list)
                })
                .catch(err => {
                    if (err.response && err.response.status === 401) {
                        history.push('/login');
                    } else {
                        console.error(err);
                        if (err.response && err.response['data'] && err.response['data'].startsWith("'Unsupported pinyin:")) {
                            setErrorMessage(err.response['data']);
                        } else {
                            setErrorMessage(err.response.data.message);
                        }
                    }
                })
                .then(list => {
                    // Continue processing the next dialogPrompt after a delay (adjust the delay value as needed)
                    // setTimeout(processNextDialogPrompt, 100);
                    // Find the index of the item with the matching ID
                    const indexToDelete = dialogPromptUpdateQueue.findIndex((tuple) => tuple.id === dialogPromptToUpdate.id);

                    if (indexToDelete !== -1) {
                        // Remove the item at the found index from the queue
                        setDialogPromptUpdateQueue((prevQueue) => [
                            ...prevQueue.slice(0, indexToDelete),
                            ...prevQueue.slice(indexToDelete + 1),
                        ]);
                    }
                    // console.log("processNextDialogPrompt() restart")
                });
        }
    }

    // Function to check if a specific dialogPrompt.id is present in the updateQueue
    function isDialogPromptInQueue(dialogPromptId: number): boolean {
        return dialogPromptUpdateQueue.some((dialogPrompt) => dialogPrompt.dialogPrompt.id === dialogPromptId);
    }
    */

    return (
        <Container sx={{ mx: 2, my: 2 }} maxWidth={false}>
            <Grid container justifyContent="center">
                {/* <Grid item sm={12} md={6} alignItems="center" justifyContent="center" sx={{ marginTop: 2 }}> */}
                {/* Left Grid with fixed width */}
                <Grid item sx={{ width: '480px', marginTop: 2 }}>
                    <Stack spacing={2}>
                        {courseList.map(course => (<div key={course.id}>
                            <Stack direction="row" alignItems="center">
                                <IconButton onClick={() => handleExpandCourse(course.id)}>
                                    {course.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                </IconButton>
                                <MenuBookRoundedIcon sx={{ mr: 2 }} />
                                <Typography onClick={() => handleCourseMenuClick(course)}>
                                    {course.name}
                                </Typography>
                            </Stack>
                            {course.expanded == true ? (
                                <Stack>
                                    {lessonList
                                        .filter(lesson => lesson.course_id === course.id)
                                        .map(lesson => (<div key={lesson.id}>
                                            <Stack direction="row" alignItems="center" sx={{ ml: 4 }}>
                                                <IconButton onClick={() => handleExpandLesson(lesson.id)}>
                                                    {lesson.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                                </IconButton>
                                                <ArticleRoundedIcon sx={{ mr: 2 }} />
                                                <Typography onClick={() => handleLessonMenuClick(lesson)}>
                                                    {lesson.name}
                                                </Typography>
                                            </Stack>
                                            {lesson.expanded == true ? (
                                                <Stack>
                                                    {dialogList
                                                        .filter(dialog => dialog.lesson_id === lesson.id)
                                                        .map(dialog => (<div key={dialog.id} >
                                                            <Stack direction="row" alignItems="center" sx={{ ml: 8 }}>
                                                                <IconButton onClick={() => handleExpandDialog(dialog.id)}>
                                                                    {dialog.expanded ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                                                                </IconButton>
                                                                <QuestionAnswerRoundedIcon sx={{ mr: 2 }} />
                                                                <Typography onClick={() => handleDialogMenuClick(dialog)}>
                                                                    {dialog.title}
                                                                </Typography>
                                                            </Stack>
                                                            {dialog.expanded == true ? (
                                                                <Stack>
                                                                    {dialogPromptList
                                                                        .filter(dialogPrompt => dialogPrompt.dialog_id === dialog.id)
                                                                        .map(dialogPrompt => {
                                                                            const processingQueue = processingQueueStatus[dialogPrompt.id] || -1;
                                                                            // const inUpdateQueue = false;
                                                                            // const inUpdateQueue = isDialogPromptInQueue(dialogPrompt.id);
                                                                            return (<div key={dialogPrompt.id}>
                                                                                <Stack direction="row" alignItems="center" sx={{ ml: 12 }}>
                                                                                    {processingQueue > 0 ? (
                                                                                        <Badge badgeContent={processingQueue} max={999} color="secondary"
                                                                                            sx={{ '.MuiBadge-badge': { backgroundColor: "#FFE299", color: "#2A2459" }, ml: 7, mr: 3 }}>
                                                                                            {/* Empty div as a placeholder */}
                                                                                            <div></div>
                                                                                        </Badge>

                                                                                    ) : (
                                                                                        <SmsRoundedIcon sx={{ fontSize: 18, ml: 6, mr: 2 }} />
                                                                                    )}
                                                                                    <Typography onClick={() => handleDialogPromptMenuClick(dialogPrompt)}>
                                                                                        {dialogPrompt.prompt && dialogPrompt.prompt.length > 20
                                                                                            ? `${dialogPrompt.prompt.substring(0, 40)} ...`
                                                                                            : dialogPrompt.prompt && dialogPrompt.prompt.length > 0
                                                                                                ? dialogPrompt.prompt
                                                                                                : dialogPrompt.class_name}
                                                                                    </Typography>
                                                                                </Stack>
                                                                            </div>)
                                                                        })
                                                                    }
                                                                    <Button variant="outlined" onClick={() => handleAddDialogPrompt(dialog.id)} sx={{ width: 'fit-content', mt: 2, ml: 16 }}>
                                                                        Add Prompt
                                                                    </Button>
                                                                </Stack>
                                                            ) : null}
                                                        </div>))
                                                    }
                                                    <Button variant="outlined" onClick={() => handleAddDialog(lesson.id)} sx={{ width: 'fit-content', mt: 2, ml: 12 }}>
                                                        Add Dialog
                                                    </Button>
                                                </Stack>
                                            ) : null}
                                        </div>))
                                    }
                                    <Button variant="outlined" onClick={() => handleAddLesson(course.id)} sx={{ width: 'fit-content', mt: 2, ml: 8 }}>
                                        Add Lesson
                                    </Button>
                                </Stack>
                            ) : null}
                        </div>))}
                        <Button variant="outlined" onClick={handleAddCourse} sx={{ width: 'fit-content', ml: 4 }}>
                            Add Course
                        </Button>
                    </Stack>
                </Grid>
                {/* <Grid item sm={12} md={6} alignItems="center" justifyContent="center" component={Paper} elevation={6}> */}
                {/* Right Grid that takes the remaining space */}
                <Grid item sx={{ flexGrow: 1, alignItems: 'center', justifyContent: 'center' }} component={Paper} elevation={6}>
                    {selectedCourse != null ? (
                        <CourseForm courseId={selectedCourse.id} onSubmit={handleCourseFormSubmit} onDelete={handleDeleteCourse} />
                    ) : null}
                    {selectedLesson != null ? (
                        <LessonForm lessonId={selectedLesson.id} onSubmit={handleLessonFormSubmit} onDelete={handleDeleteLesson} />
                    ) : null}
                    {selectedDialog != null ? (
                        <DialogForm dialogId={selectedDialog.id} onSubmit={handleDialogFormSubmit} onDelete={handleDeleteDialog} />
                    ) : null}
                    {selectedDialogPrompt != null ? (
                        <DialogPromptForm dialogPromptId={selectedDialogPrompt.id} onSubmit={handleDialogPromptFormSubmit} onDelete={handleDeleteDialogPrompt} />
                    ) : null}
                </Grid>
            </Grid>
        </Container>
    );
}
